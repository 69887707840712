import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { StepperCandidate } from "../../../Context/StepperCandidate";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { useLocation } from "react-router-dom";
import Message from "../Message/Message";

const TelephonicInterviewReview = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present
  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const { workflowSession, getWorkflowSession } = useContext(WorkflowContext);

  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const { isTelLoading,
    telephonicData,
    getTelephonicData } =
    useContext(StepperCandidate);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");

  // const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);
  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === formId?.id) ||
    !privileges.includes("Telephonic Interview Review Create");

  console.log("telephonicData: ", telephonicData);
  console.log(telephonicData.length);


  const location = useLocation();

  const candidate_id = location.state?.candidate_id;
  const requisition_id = location.state?.requisition_id;

  console.log(candidate_id);
  console.log(requisition_id);
  const questions_array = [
    { id: 1, question: "Six Days Working", answer: "" },
    { id: 2, question: "Saturday & Sunday Rotational", answer: "" },
    { id: 3, question: "Location Suitability", answer: "" },
    { id: 4, question: "Notice Period", answer: "" },
    { id: 5, question: "Flexible Notice Period", answer: "" },
    { id: 6, question: "Relevant vs Required Experience", answer: "" },
    { id: 7, question: "Relevant Experience Score", answer: "" },
    { id: 8, question: "Technical Profile vs Skillsets", answer: "" },
    { id: 9, question: "Relevant Academic Validation Score", answer: "" },
    { id: 10, question: "Skillset to Profile Appropriation Score", answer: "" },
    { id: 11, question: "Stability Score", answer: "" },
    { id: 12, question: "Candidate CTC vs Budget CTC", answer: "" },
    { id: 13, question: "Reasons to change the job", answer: "" },
  ];

  const [answers, setAnswers] = useState(questions_array);

  console.log(answers);

  const [noticePeriod, setNoticePeriod] = useState(null);
  console.log(noticePeriod);
  const [experience, setExperience] = useState(null);
  const [budget, setBudget] = useState(null);
  const [reason, setReason] = useState(null);

  let updatedData = answers;
  console.log(updatedData);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const handleOldData = () => {
    if (telephonicData?.interview_question_score) {
      telephonicData?.interview_question_score?.map((info, index) => {
        updatedData = [...updatedData];
        if (index <= 12) {
          console.log(index);
          updatedData[index] = {
            ...updatedData[index],
            answer: info?.answer,
            anwser_status: true,
          };
          setNoticePeriod(updatedData[3]?.answer);
          setExperience(updatedData[5]?.answer);
          setBudget(updatedData[11]?.answer);
          setReason(updatedData[12]?.answer);
          setAnswers(updatedData);
        }
      });
    }
    console.log(telephonicData);
  };

  const onSubmit = (data) => {
    console.log(data);
    let hr_telephonic = JSON.stringify({
      requisition_id: requisition_id,
      candidate_job_id: candidate_id,
      candidate_interview_id: telephonicData?.interview_details?.id,
      total_score: data.hr_telephonic_score,
      candidate_status:
        data?.is_selected === "Draft"
          ? 0
          : data?.is_selected === "Accept"
            ? 1
            : data?.is_selected === "Reject"
              ? 2
              : null,
      comments: data?.comments,
      updated_by_id: userID,
      workflow_session: {
        candidate_job_id: candidate_id,
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
        created_by_id: userID,
        updated_by_id: userID,
      },
    });

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api/hr-telephonic/${telephonicData?.interview_details?.id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: hr_telephonic,
    };

    console.log(hr_telephonic);
    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: data?.is_selected === "Draft" ? "Candidate drafted successfully." : data?.is_selected === "Reject" ? "Candidate rejected successfully." : "Candidate accepted successfully.",
          });
          nextLevel();
          getWorkflowSession(candidate_id, requisition_id);
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Failed to review. Please try again.",
        });
      });
  };

  // TAT Submit
  const onTelephonicTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: requisition_id,
      candidate_id: candidate_id,
      stage: "HR Telephonic Interview Review",
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidatestatus/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleOldData();
  }, [telephonicData]);

  useEffect(() => {
    console.log(telephonicData);
    setValue(
      "is_selected",
      telephonicData?.interview_details?.candidate_accept_reject === 0
        ? "Draft"
        : telephonicData?.interview_details?.candidate_accept_reject === 1
          ? "Accept"
          : telephonicData?.interview_details?.candidate_accept_reject === 2
            ? "Reject"
            : "Draft"
    );
    setValue("hr_telephonic_score", telephonicData?.interview_score?.score);
    setValue("comments", telephonicData?.interview_details?.comments);
  }, [telephonicData]);

  useEffect(() => {
    getTelephonicData(requisition_id, candidate_id);
    if (privileges.includes("Telephonic Interview Review Create")) {
      onTelephonicTATSubmit();
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Telephonic Interview Review
            </h3>
            {isTelLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isTelLoading ? (
        <div></div>
      ) : (
        <>
          <div className="nk-block">
            {telephonicData?.interview_details?.candidate_accept_reject === 2 ?
              <Message icon={require("../../../../Resources/images/rejected.png")}
                message={"The candidate has been disqualified at this stage of the recruitment process."} />
              :
              <div className="card">
                <div className="card-inner">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="row g-4">
                      {telephonicData &&
                        telephonicData?.interview_question_score &&
                        telephonicData?.interview_question_score?.length !== 0 ? (
                        <>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">Six Days Working</div>
                              <div
                                className="btn-group mt-2 d-flex align-items-center"
                                aria-label="Basic example"
                              >
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-good1"
                                  className="me-2"
                                >
                                  {answers[0]?.answer === "Good" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-okay1"
                                  className="me-2"
                                >
                                  {answers[0]?.answer === "Okay" ? (
                                    <img
                                      src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>

                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-bad1"
                                >
                                  {answers[0]?.answer === "Bad" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">
                                Saturday & Sunday Rotational
                              </div>
                              <div
                                className="btn-group mt-2 d-flex align-items-center"
                                aria-label="Basic example"
                              >
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-good2"
                                  className="me-2"
                                >
                                  {answers[1]?.answer === "Good" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-okay2"
                                  className="me-2"
                                >
                                  {answers[1]?.answer === "Okay" ? (
                                    <img
                                      src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-bad2"
                                >
                                  {answers[1]?.answer === "Bad" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">Location Suitability</div>
                              <div
                                className="btn-group mt-2 d-flex align-items-center"
                                aria-label="Basic example"
                              >
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-good3"
                                  className="me-2"
                                >
                                  {answers[2]?.answer === "Good" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>

                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-okay3"
                                  className="me-2"
                                >
                                  {answers[2]?.answer === "Okay" ? (
                                    <img
                                      src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-bad3"
                                >
                                  {answers[2]?.answer === "Bad" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">Notice Period</div>
                              <input
                                className="form-control"
                                type="text"
                                id="text-forms-requiredexp"
                                value={
                                  noticePeriod == 0 ? "Immediate" : noticePeriod
                                }
                                placeholder="Enter required experience"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">Flexible Notice Period</div>
                              <div
                                className="btn-group mt-2 d-flex align-items-center"
                                aria-label="Basic example"
                              >
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-good5"
                                  className="me-2"
                                >
                                  {answers[4]?.answer === "Good" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>

                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-okay5"
                                  className="me-2"
                                >
                                  {answers[4]?.answer === "Okay" ? (
                                    <img
                                      src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-bad5"
                                >
                                  {answers[4]?.answer === "Bad" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">
                                Relevant vs Required Experience
                              </div>
                              <div className="d-flex">
                                <input
                                  className="form-control me-1"
                                  type="text"
                                  id="text-forms-relevantexp"
                                  value={experience}
                                  placeholder="Enter relevant experience"
                                  disabled
                                />
                                <input
                                  className="form-control"
                                  type="text"
                                  value={
                                    telephonicData?.interview_details?.requisition
                                      ?.required_experience || "-"
                                  }
                                  id="text-forms-requiredexp"
                                  placeholder="Enter required experience"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">Relevant Exp. Score</div>
                              <div
                                className="btn-group mt-2 d-flex align-items-center"
                                aria-label="Basic example"
                              >
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-good7"
                                  className="me-2"
                                >
                                  {answers[6]?.answer === "Good" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>

                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-okay7"
                                  className="me-2"
                                >
                                  {answers[6]?.answer === "Okay" ? (
                                    <img
                                      src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-bad7"
                                >
                                  {answers[6]?.answer === "Bad" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">
                                Technical Profile vs Skillsets
                              </div>
                              <div
                                className="btn-group mt-2 d-flex align-items-center"
                                aria-label="Basic example"
                              >
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-good8"
                                  className="me-2"
                                >
                                  {answers[7]?.answer === "Good" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>

                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-okay8"
                                  className="me-2"
                                >
                                  {answers[7]?.answer === "Okay" ? (
                                    <img
                                      src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-bad8"
                                >
                                  {answers[7]?.answer === "Bad" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">
                                Relevant Academic Validation Score
                              </div>
                              <div
                                className="btn-group mt-2 d-flex align-items-center"
                                aria-label="Basic example"
                              >
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-good9"
                                  className="me-2"
                                >
                                  {answers[8]?.answer === "Good" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-okay9"
                                  className="me-2"
                                >
                                  {answers[8]?.answer === "Okay" ? (
                                    <img
                                      src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>

                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-bad9"
                                >
                                  {answers[8]?.answer === "Bad" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">
                                Skillset to Profile Appropriation score
                              </div>
                              <div
                                className="btn-group mt-2 d-flex align-items-center"
                                aria-label="Basic example"
                              >
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-good10"
                                  className="me-2"
                                >
                                  {answers[9]?.answer === "Good" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up.png")}
                                      alt="good"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>

                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-okay10"
                                  className="me-2"
                                >
                                  {answers[9]?.answer === "Okay" ? (
                                    <img
                                      src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-bad10"
                                >
                                  {answers[9]?.answer === "Bad" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">Stability Score</div>
                              <div
                                className="btn-group mt-2 d-flex align-items-center"
                                aria-label="Basic example"
                              >
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-good11"
                                  className="me-2"
                                >
                                  {answers[10]?.answer === "Good" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up-fill.png")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-up.png")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-okay11"
                                  className="me-2"
                                >
                                  {answers[10]?.answer === "Okay" ? (
                                    <img
                                      src={require("../../../../Resources/images/NeutralColorfill-ezgif.com-video-to-gif-converter.gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/palm-down-hand-GIF (1).gif")}
                                      alt="okay"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  id="button-forms-bad11"
                                >
                                  {answers[10]?.answer === "Bad" ? (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down-fill.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={require("../../../../Resources/images/thumbs-down.png")}
                                      alt="Bad"
                                      style={{
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">
                                Candidate CTC vs Budget CTC
                              </div>
                              <div className="d-flex">
                                <input
                                  className="form-control me-1"
                                  type="text"
                                  value={budget}
                                  id="text-forms-cordidates"
                                  placeholder="Enter cordidates"
                                  disabled
                                />
                                <input
                                  className="form-control"
                                  type="text"
                                  value={
                                    // (telephonicData?.interview_details
                                    //   ?.requisition?.min_salary || "-") +
                                    // "-" +
                                    (telephonicData?.interview_details
                                      ?.requisition?.max_salary || "-")
                                  }
                                  id="text-forms-budget"
                                  placeholder="Enter budget"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="nk-block-head-content">
                              <div className="h6">Reason to change the job</div>
                              <textarea
                                className="form-control"
                                id="textarea-forms-reason"
                                value={reason}
                                placeholder="Reason to change the job"
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 mt-4 mb-4">
                            <div className="form-group">
                              <label className="form-label">Comments</label>
                              <textarea
                                className="form-control"
                                {...register("comments")}
                                disabled={isFieldsDisabled || telephonicData?.interview_details?.candidate_accept_reject !== null && telephonicData?.interview_details?.candidate_accept_reject !== 0}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-center">
                            <div className="form-control-wrap d-flex">
                              <label
                                className="form-label mb-0"
                                style={{
                                  width: "70px",
                                  cursor: "default",
                                  alignSelf: "center",
                                }}
                              >
                                Score:
                              </label>
                              <div className="input-group">
                                <input
                                  type="tel"
                                  className="form-control ms-2"
                                  id="number-form-hr_telephonic_score"
                                  placeholder="Enter score"
                                  {...register("hr_telephonic_score", {
                                    required: "Score is required",
                                  })}
                                  onWheel={(e) => e.target.blur()}
                                  disabled={isFieldsDisabled || telephonicData?.interview_details?.candidate_accept_reject !== null && telephonicData?.interview_details?.candidate_accept_reject !== 0}
                                />
                              </div>
                            </div>
                            {errors?.hr_telephonic_score && (
                              <span className="fs-8 text-danger">
                                {errors?.hr_telephonic_score?.message}
                              </span>
                            )}
                          </div>

                          <div className="col-lg-6 col-md-6 col-sm-12"></div>

                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <ul className="row g-3">
                              {interviewActionDropdownData.map(
                                (action, index) => (
                                  <li className="col-sm-4" key={index}>
                                    <div className="custom-control custom-radio me-3">
                                      <input
                                        type="radio"
                                        id={`checkbox-form-is-selected-telephonic-${action.value.toLowerCase()}`}
                                        className="custom-control-input"
                                        value={action.value}
                                        {...register("is_selected", {
                                          required: true,
                                        })}
                                        disabled={isFieldsDisabled || telephonicData?.interview_details?.candidate_accept_reject !== null && telephonicData?.interview_details?.candidate_accept_reject !== 0}
                                      />
                                      <label
                                        className="custom-control-label"
                                        for={`checkbox-form-is-selected-telephonic-${action.value.toLowerCase()}`}
                                      >
                                        {action.name}
                                      </label>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                            {errors.is_selected && (
                              <span className="fs-8 text-danger">
                                Please select any one
                              </span>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          No HR telephonic interview details found.
                        </div>
                      )}
                      <div className="col-12 d-flex">
                        <div className="form-group">
                          {level !== 0 && (
                            <button
                              type="button"
                              className="btn btn-lg btn-primary me-3"
                              id="button-button-prevlevel"
                              onClick={() => prevLevel()}
                            >
                              Prev
                            </button>
                          )}
                        </div>
                        <div className="form-group">
                          {submitLoading ? (
                            <button className="btn text-dark" disabled>
                              Loading...
                            </button>
                          ) : (
                            <>
                              {sequenceList.length - 2 > level ? (
                                <>
                                  {isFieldsDisabled || telephonicData?.interview_details?.candidate_accept_reject !== null && telephonicData?.interview_details?.candidate_accept_reject !== 0 ? (
                                    <button
                                      type="button"
                                      className="btn btn-lg btn-primary"
                                      id="button-button-nextlevel"
                                      onClick={() => nextLevel()}
                                    >
                                      Next
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                      id="submit-button-techinterview"
                                    >
                                      Next
                                    </button>
                                  )}
                                </>
                              ) : telephonicData?.interview_details
                                ?.interview_action ? (
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-primary"
                                  id="submit-button-telephonicround"
                                // onClick={() => nextLevel()}
                                >
                                  Submit
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-primary"
                                  id="submit-button-telephonicround"
                                >
                                  Submit
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          </div>
        </>
      )}
      <Snackbar />
    </>
  );
};

export default TelephonicInterviewReview;
