import React, { useContext, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { ScheduleCandidateInterviewContext } from "../../Context/ScheduleCandidateInterviewContext";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

const ScheduleFreeSlots = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const userID = secureLocalStorage.getItem("userID");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  const privileges = secureLocalStorage.getItem("privileges");

  const { isValid } = useFormState({ control });

  const { getInterviewSlots } = useContext(ScheduleCandidateInterviewContext);

  const currentDate = new Date().toISOString().split("T")[0];

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    const start_date = moment(data.start_at)
      .utcOffset("+05:30")
      .format()
      .split("+");
    const end_date = moment(data.start_at)
      .add(1, "hours")
      .utcOffset("+05:30")
      .format()
      .split("+");
    var free_slot = JSON.stringify({
      slot_start_time: start_date[0],
      slot_end_time: end_date[0],
      interviewer_id: userID,
      created_by_id: userID,
    });
    console.log(free_slot);

    const url = VARIABLES.url + `/api/interview-slots/`;
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: free_slot,
    };

    setSubmitLoading(true);
    setSaveLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSubmitLoading(false);
        setSaveLoading(false);
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Free slot added successfully",
        });
        reset();
        getInterviewSlots();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          setSaveLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSaveLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to add a free slot",
          });
        }
      });
  };

  const validateDate = (value) => {
    const currentDate = new Date().toISOString().split("T")[0];
    console.log(value);
    if (value >= currentDate) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getInterviewSlots();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="free_slot"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {/* Add free slot - {props.requisitionData?.job_opening?.job_title} */}
                Add Free Slots
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-label">
                    Event Start Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="text-forms-start_at"
                    min={currentDate}
                    placeholder="Describe your event title"
                    {...register("start_at", {
                      required: "true",
                      validate: validateDate,
                    })}
                  />
                  {errors.start_at && errors.start_at.type === "required" && (
                    <span className="fs-8 text-danger">
                      Event start date is required
                    </span>
                  )}
                  {errors.start_at && errors.start_at.type === "validate" && (
                    <span className="fs-8 text-danger">
                      Date must be today or later
                    </span>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                {saveLoading ? (
                  <button
                    id="button-loading"
                    className="btn text-dark"
                    disabled
                  >
                    Loading...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-lg btn-primary">
                    Save & Continue
                  </button>
                )}
                {submitLoading ? (
                  <button
                    id="button-loading"
                    className="btn text-dark"
                    disabled
                  >
                    Loading...
                  </button>
                ) : isValid === true ? (
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary"
                    id="submit-button-free_slot"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary"
                    id="submit-button-free_slot"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>

      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScheduleFreeSlots;
