import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import Swal from "sweetalert2";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import DatePicker from "rsuite/DatePicker";
import "rsuite/DatePicker/styles/index.css";
import { format } from "date-fns";

const EditRequested = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [filteredTeamData, setFilteredTeamData] = useState([]);
  console.log(filteredTeamData);
  const [filteredPositionData, setFilteredPositionData] = useState([]);
  console.log(filteredPositionData);

  const [departmentEntry, setDepartmentEntry] = useState(null);
  console.log(departmentEntry);
  const [teamEntry, setTeamEntry] = useState(null);
  console.log(teamEntry);
  const [positionEntry, setPositionEntry] = useState(null);

  const [activeStatus, setActiveStatus] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    resetField,
    setValue
  } = useForm();

  let location = useLocation();
  console.log(location);
  let navigate = useNavigate();

  const { getRequisitionByUserData } = useContext(RequestedJobOpeningContext);

  //DROPDOWN DATA
  const {
    isDropdownDataLoading,
    jobTypeDropdownData,
    priorityDropdownData,
    requisitionReasonDropdownData,
    jobLocationDropdownData,
    deadlineDropdownData
  } = useContext(TypemasterDropdownContext);

  const {
    isGenricDropdownDataLoading,
    isGenricParamsDropdownDataLoading,
    departmentDropdownData,
    teamDropdownData,
    positionDropdownData,
    jobOpeningDropdownData,
    seniorityDropdownData,
  } = useContext(GenericDropdownContext);

  const jobID = location.state.editpost?.id;
  console.log(location.state.editpost);

  const requiredExperienceRange = watch("required_experience_range");

  const disablePastDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  // const validateMaxSalary = (value) => {
  //   return (
  //     parseFloat(value) > minSalary ||
  //     "Max Salary must be greater than Min Salary"
  //   );
  // };

  const validateMaxSalary = (value) => {
    console.log(parseFloat(value))
    if (value) {
      return (
        parseFloat(value) <= 800 ||
        "Limit exceeded"
      );
    }
  };

  const handleExperienceRange = (value) => {
    console.log(parseFloat(value))
    if (value) {
      if (requiredExperienceRange) {
        return (
          parseFloat(value) < requiredExperienceRange ||
          "Please enter valid range"
        );
      }
    }
  };

  const handleUpdaingTeam = (e) => {
    setValue('position', null)
    setFilteredPositionData([]);
    // resetField("position");
  }

  const handleUpdaingDepartment = (e) => {
    setValue('team', null)
    setValue('position', null)
    setFilteredPositionData([]);
    // resetField("position");
  }

  // const handlePriority = (e) => {
  //   console.log(e);
  //   if (e.value) {
  //     let priorityValue = null;
  //     if (e.value === "Replacement") {
  //       priorityValue = { value: "High", label: "High" };
  //     } else if (e.value === "Expansion") {
  //       priorityValue = { value: "Medium", label: "Medium" };
  //     } else if (e.value === "Temporary") {
  //       priorityValue = { value: "Low", label: "Low" };
  //     }

  //     setValue("priority", priorityValue);
  //   }
  // };

  const [jobOpeningID, setJobOpeningID] = useState(null);
  console.log(jobOpeningID);

  const handleJobOpeningID = (data) => {
    console.log("jobOpeningDropdownData: ", jobOpeningDropdownData);
    const getJobOpeningID = jobOpeningDropdownData?.find(
      (info) =>
        info.seniority_pk === data?.value &&
        info.position_pk === positionEntry?.value
    );
    setJobOpeningID(getJobOpeningID);
    if (getJobOpeningID === undefined) {
      Swal.fire({
        title: `Please contact admin`,
        text: `Job opening for ${data?.label || "-"} ${positionEntry?.label || "-"
          } is not created yet! `,
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.isConfirmed) {
          resetField("seniority");
        }
      });
    }
  };
  console.log(jobOpeningID);

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    const experience = data.required_experience_range
      ? `${data.required_experience}-${data.required_experience_range}`
      : data.required_experience;

    let priorityValue = null;
    if (data.requisition_reason.value === "Replacement") {
      priorityValue = "High"
    } else if (data.requisition_reason.value === "Expansion") {
      priorityValue = "Medium"
    } else if (data.requisition_reason.value === "Temporary") {
      priorityValue = "Low"
    }

    var job_posting = JSON.stringify({
      job_opening_id: jobOpeningID?.id,
      job_type: data.job_type?.value,
      reason_to_raise_requisition: data.requisition_reason?.value,
      job_location: data.job_location.value,
      required_experience: experience,
      no_of_vacancy: data.no_of_vacancy,
      // min_salary: data.min_salary,
      max_salary: data.max_salary ? data.max_salary : null,
      // deadline: data.deadline
      //   ? format(new Date(data.deadline), "yyyy-MM-dd'T'HH:mm")
      //   : null,
      deadline: data?.deadline?.value,
      priority: priorityValue,
      comments: data.comments,
      pointers: data.jd_pointers,
      keywords: data.keywords,
      updated_by_id: userID,
      requested_user_id: userID
      // requested_user_id: data.requestedusername
      //   ? data.requestedusername.value
      //   : userID,
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/requestrequisition/${jobID}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: job_posting,
    };

    console.log(job_posting);
    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully edited requisition",
        });
        getRequisitionByUserData();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to edit requisition",
          });
        }
      });
  };

  const deletePosition = () => {
    const access = secureLocalStorage.getItem("access");
    const url =
      VARIABLES.url +
      `/api/requestrequisition/${location.state.editpost.id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    axios(config)
      .then((response) => {
        getRequisitionByUserData();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the requisition",
        });
        console.log(response.data);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setActiveStatus(0);
          RefreshToken();
        } else {
          setActiveStatus(1);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to delete the requisition",
          });
        }
      });
  };

  const deletePositionAlert = () => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deletePosition();
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    console.log(location.state.editpost);
    const editedJobPost = location.state.editpost;
    if (editedJobPost) {
      var defaultValues = {};
      if (editedJobPost.required_experience.includes('-')) {
        const [experience, experienceRange] = editedJobPost.required_experience.split('-');
        console.log("experience: ", experience)
        console.log("experienceRange: ", experienceRange)
        defaultValues.required_experience = experience.trim();
        defaultValues.required_experience_range = experienceRange.trim();
      } else {
        defaultValues.required_experience = editedJobPost.required_experience;
      }
      // setValue('required_experience', defaultValues.required_experience);
      // setValue('required_experience_range', defaultValues.required_experience_range);
      defaultValues.no_of_vacancy = editedJobPost?.no_of_vacancy;
      // defaultValues.min_salary = editedJobPost?.min_salary;
      defaultValues.max_salary = editedJobPost?.max_salary;
      defaultValues.comments = editedJobPost?.comments;
      defaultValues.jd_pointers = editedJobPost?.pointers;
      defaultValues.keywords = editedJobPost?.keywords;

      // if (editedJobPost?.deadline) {
      //   const deadlineDate = new Date(editedJobPost.deadline);
      //   defaultValues.deadline = deadlineDate;
      // }

      if (editedJobPost.job_opening?.department) {
        const getDepartment = {
          value: editedJobPost.job_opening?.department?.id,
          label: editedJobPost.job_opening?.department?.department_display_name,
        };
        defaultValues.department = getDepartment;
        setDepartmentEntry(getDepartment);
      }

      if (editedJobPost?.job_opening?.team) {
        const getTeam = {
          value: editedJobPost.job_opening?.team?.id,
          label: editedJobPost.job_opening?.team?.team_display_name,
        };
        defaultValues.team = getTeam;
        setTeamEntry(getTeam);
      }

      if (editedJobPost?.job_opening?.position) {
        const getPosition = {
          value: editedJobPost.job_opening?.position?.id,
          label: editedJobPost.job_opening?.position?.position_display_name,
        };
        defaultValues.position = getPosition;
        setPositionEntry(getPosition);
      }

      if (editedJobPost?.job_opening?.seniority) {
        const getSeniority = {
          value: editedJobPost.job_opening?.seniority?.id,
          label: editedJobPost.job_opening?.seniority?.seniority_display_name,
        };
        defaultValues.seniority = getSeniority;
      }

      if (editedJobPost?.job_location) {
        const getLocation = {
          value: editedJobPost.job_location,
          label: editedJobPost.job_location,
        };
        defaultValues.job_location = getLocation;
      }
      if (editedJobPost?.reason_to_raise_requisition) {
        const getReason = {
          value: editedJobPost.reason_to_raise_requisition,
          label: editedJobPost.reason_to_raise_requisition,
        };
        defaultValues.requisition_reason = getReason;
      }

      if (editedJobPost?.requested_user) {
        const getRequestedUser = {
          value: editedJobPost?.requested_user?.id,
          label:
            editedJobPost?.requested_user?.first_name +
            " " +
            editedJobPost?.requested_user?.last_name,
        };
        defaultValues.requestedusername = getRequestedUser;
      }

      const getJobType = {
        value: editedJobPost.job_type,
        label: editedJobPost.job_type,
      };
      defaultValues.job_type = getJobType;

      // const getPriority = {
      //   value: editedJobPost.priority,
      //   label: editedJobPost.priority,
      // };
      // defaultValues.priority = getPriority;

      const getDeadline = {
        value: editedJobPost.deadline,
        label: editedJobPost.deadline,
      };
      defaultValues.deadline = getDeadline;

      reset(defaultValues);
    }
  }, []);

  useEffect(() => {
    const rawfilteredTeamData = teamDropdownData.filter(
      (info) => info?.department_display_name == departmentEntry?.label
    );
    setFilteredTeamData(rawfilteredTeamData);
    // resetField("team");
    // resetField("position");
    // resetField("seniority");
  }, [departmentEntry]);

  useEffect(() => {
    const rawfilteredPositionData = positionDropdownData.filter(
      (info) => info?.team_display_name == teamEntry?.label
    );
    setFilteredPositionData(rawfilteredPositionData);
    // resetField("position");
    // resetField("seniority");
  }, [teamEntry]);

  // useEffect(() => {
  //   resetField("seniority");
  //   setValue('seniority', null)
  // }, [positionEntry]);

  useEffect(() => {
    // getRequisitionByUserData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">
              Edit {location.state.editpost?.job_opening?.job_title}
            </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner d-flex justify-content-end pt-3 pb-0">
            {privileges.includes("requisition_delete") ? (
              <div className="d-flex align-center justify-center">
                <em className="icon ni ni-trash me-2 fs-4 text-danger"></em>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input bg-danger"
                    id="submit-button-deleterequisition"
                    checked={activeStatus}
                    onChange={(e) => {
                      deletePositionAlert(location.state?.editpost?.id);
                    }}
                  />
                  <label
                    className="custom-control-label"
                    id="button-button-status"
                    htmlFor="submit-button-deleterequisition"
                  ></label>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Department <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={departmentDropdownData?.map((info) => ({
                            value: info?.id,
                            label: info?.department_display_name,
                          }))}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          onChange={(e) => {
                            setDepartmentEntry(e);
                            handleUpdaingDepartment(e);
                            field.onChange(e);
                          }}
                          id="select-forms-department"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="department"
                      control={control}
                    />
                    {errors.department && (
                      <span className="fs-8 text-danger">
                        Department name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Team <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={filteredTeamData?.map((info) => ({
                            value: info.team_display_name,
                            label: info.team_display_name,
                          }))}
                          isClearable={true}
                          onChange={(e) => {
                            setTeamEntry(e);
                            handleUpdaingTeam(e);
                            field.onChange(e);
                          }}
                          id="select-forms-team"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="team"
                      control={control}
                    />
                    {errors.team && (
                      <span className="fs-8 text-danger">
                        Team name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Position <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="position"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={filteredPositionData?.map((info) => ({
                            value: info.id,
                            label: info.position_display_name,
                          }))}
                          isClearable={true}
                          onChange={(e) => {
                            setPositionEntry(e);
                            field.onChange(e);
                          }}
                          id="select-forms-position"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                    />
                    {errors.position && (
                      <span className="fs-8 text-danger">
                        Position is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Seniority <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={seniorityDropdownData?.map((info) => ({
                            value: info.id,
                            label: info.seniority_display_name,
                          }))}
                          onChange={(e) => {
                            handleJobOpeningID(e);
                            field.onChange(e);
                          }}
                          isClearable={true}
                          isLoading={isGenricDropdownDataLoading}
                          id="select-forms-seniority"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="seniority"
                      control={control}
                    />
                    {errors.seniority && (
                      <span className="fs-8 text-danger">
                        Seniority is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Type <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={jobTypeDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isDropdownDataLoading}
                          id="select-forms-job_type"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="job_type"
                      control={control}
                    />
                    {errors.job_type && (
                      <span className="fs-8 text-danger">
                        Job type is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Job Location <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={jobLocationDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isGenricDropdownDataLoading}
                          id="select-forms-job_location"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="job_location"
                      control={control}
                    />
                    {errors.job_location && (
                      <span className="fs-8 text-danger">
                        Job Location is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Required Experience (in Years)
                      <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id="number-forms-required_experience"
                        placeholder="Enter Required Experience"
                        onWheel={(e) => e.target.blur()}
                        {...register("required_experience", {
                          required: true,
                          validate: handleExperienceRange
                        })}
                      />
                      <span className="ms-1 me-1 align-self-center">to</span>
                      <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        id="number-forms-required_experience_range"
                        placeholder="Enter required experience range"
                        onWheel={(e) => e.target.blur()}
                        {...register("required_experience_range")}
                      />
                    </div>
                    {errors.required_experience &&
                      errors.required_experience.type === "required" && (
                        <span className="fs-8 text-danger">
                          Please enter your experience
                        </span>
                      )}
                    {errors.required_experience &&
                      errors.required_experience.type === "validate" && (
                        <span className="fs-8 text-danger">
                          Please enter valid range
                        </span>
                      )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      No of Vacancy <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="number-forms-no_of_vacancy"
                      placeholder="Enter no of vacancy"
                      onWheel={(e) => e.target.blur()}
                      {...register("no_of_vacancy", {
                        required: true,
                        pattern: { value: /^\d+$/ },
                      })}
                    />
                    {errors.no_of_vacancy &&
                      errors.no_of_vacancy.type === "required" && (
                        <span className="fs-8 text-danger">
                          No of Vacancy is required
                        </span>
                      )}
                    {errors.no_of_vacancy &&
                      errors.no_of_vacancy.type === "pattern" && (
                        <span className="fs-8 text-danger">
                          No of Vacancy cannot be negative or in decimal
                        </span>
                      )}
                  </div>
                </div>

                {/* <div className="col-lg-6">
                  <div className="form-control-wrap">
                    <label className="form-label">
                      Minimum Salary <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="number-forms-min_salary"
                        placeholder="Enter minimum salary"
                        onWheel={(e) => e.target.blur()}
                        {...register("min_salary", {
                          required: true,
                          pattern: { value: /^\d{1,2}$/, message: "Salary should be a number with up to 2 digits" },
                        })}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          lakhs per annum
                        </span>
                      </div>
                    </div>
                    {errors.min_salary && errors.min_salary.type === "required" && (
                      <span className="fs-8 text-danger">Min Salary is required</span>
                    )}
                    {errors.min_salary && errors.min_salary.type === "pattern" && (
                      <span className="fs-8 text-danger">{errors.min_salary.message}</span>
                    )}
                  </div>
                </div> */}

                <div className="col-lg-6">
                  <div className="form-control-wrap mb-0">
                    <label className="form-label">
                      Maximum Salary
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        id="number-forms-max_salary"
                        placeholder="Enter maximum salary"
                        step="0.01"
                        onWheel={(e) => e.target.blur()}
                        {...register("max_salary", {
                          // required: true,
                          validate: validateMaxSalary,
                          // pattern: { value: /^\d{1,2}$/, message: "Salary should be a number with up to 2 digits" },
                        })}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          lakhs per annum
                        </span>
                      </div>
                    </div>
                    {errors.max_salary && errors.max_salary.type === "required" && (
                      <span className="fs-8 text-danger">Max Salary is required</span>
                    )}
                    {errors.max_salary && errors.max_salary.type === "validate" && (
                      <span className="fs-8 text-danger">{errors.max_salary.message}</span>
                    )}
                    {errors.max_salary && errors.max_salary.type === "pattern" && (
                      <span className="fs-8 text-danger">{errors.max_salary.message}</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Reasons to Raise a Requisition
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={requisitionReasonDropdownData?.map(
                            (info) => ({
                              value: info.name,
                              label: info.name,
                            })
                          )}
                          // onChange={(e) => {
                          //   handlePriority(e);
                          //   field.onChange(e);
                          // }}
                          isLoading={isGenricDropdownDataLoading}
                          isClearable={true}
                          id="select-forms-requisition_reason"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="requisition_reason"
                      control={control}
                    />
                    {errors.requisition_reason && (
                      <span className="fs-8 text-danger">
                        Requisition reason is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Deadline (in days)</label>
                    {/* <Controller
                      name="deadline"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          format="dd.MM.yyyy hh:mm aa"
                          style={{ width: "500px", minWidth: "100%" }}
                          disabledDate={disablePastDates}
                        />
                      )}
                    /> */}
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={deadlineDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isDropdownDataLoading}
                          id="select-forms-deadline"
                        />
                      )}
                      defaultValue=""
                      // rules={{ required: true }}
                      name="deadline"
                      control={control}
                    />
                  </div>
                </div>

                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Priority <span className="text-danger">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={priorityDropdownData?.map((info) => ({
                            value: info.name,
                            label: info.name,
                          }))}
                          isClearable={true}
                          isLoading={isDropdownDataLoading}
                          id="select-forms-priority"
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="priority"
                      control={control}
                    />
                    {errors.priority && (
                      <span className="fs-8 text-danger">
                        Priority is required
                      </span>
                    )}
                  </div>
                </div> */}

                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">JD Pointers <span className="text-danger"> *</span></label>
                    <textarea
                      type="datetime-local"
                      className="form-control"
                      id="textarea-forms-jd_pointers"
                      placeholder="Enter JD pointers"
                      {...register("jd_pointers", {
                        required: true
                      })}
                    />
                    {errors.jd_pointers && (
                      <span className="fs-8 text-danger">
                        JD Pointers is required
                      </span>
                    )}
                  </div>
                </div> */}

                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Keywords <span className="text-danger"> *</span>
                    </label>
                    <textarea
                      type="datetime-local"
                      className="form-control"
                      id="textarea-forms-keywords"
                      placeholder="Enter keywords"
                      {...register("keywords", {
                        required: true
                      })}
                    />
                    {errors.keywords && (
                      <span className="fs-8 text-danger">
                        Keywords is required
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Comments</label>
                    <textarea
                      type="datetime-local"
                      className="form-control"
                      id="textarea-forms-comments"
                      placeholder="Enter comments"
                      {...register("comments")}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createjobopening"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditRequested;
