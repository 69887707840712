import React, { useContext, useEffect } from "react";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import Snackbar from "../../../Base/Snackbar";
import { useLocation } from "react-router-dom";
import { VARIABLES } from "../../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const MettleTestUpload = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;
  const userID = secureLocalStorage.getItem("userID");

  // Candidate Exam / Interview data of particular candidates stores in "candidateExam"
  const { isCandidateExamLoading, getCandidateExamData } = useContext(MDApprovalContext);

  // TAT Submit
  const onPsychometryTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: requisition_id,
      candidate_id: candidate_id,
      stage: "Psychometric Test Submission",
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidatestatus/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCandidateExamData(candidate_id, requisition_id);
    onPsychometryTATSubmit();
  }, []);


  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Psychometric Test </h4>
            {isCandidateExamLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <h6>You don't have access to this form.</h6>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default MettleTestUpload;
