import React, { useContext, useEffect, useState } from "react";
import { MDApprovalContext } from "../../../Context/MDApprovalContext";
import { useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Snackbar from "../../../Base/Snackbar";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import axios from "axios";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constants";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import moment from "moment";
// import { assignment } from '../../WorkflowStepperForm/StepperFormJson'

const AssignmentUpload = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");

  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  // assignment details according to candidates
  const {
    assignment,
    isAssignmentDataLoading, getAssignmentData } =
    useContext(MDApprovalContext); //gets the data on behalf of particular candidate

  // To call workflow session after the POST/ PATCH request
  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  const { setSubmitMessage } = useContext(SnackbarContext);

  const [workflowId, setWorkflowId] = useState(null);
  console.log(workflowSession);

  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === formId?.id) ||
    !privileges.includes("Assignment Upload");

  const [filePreview, setFilePreview] = useState(null);

  const isFutureDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    console.log(today.setHours(0, 0, 0, 0));
    const assignmentDate = new Date(date);
    assignmentDate?.setHours(0, 0, 0, 0);
    console.log(assignmentDate?.setHours(0, 0, 0, 0));
    if (assignmentDate <= today) {
      return true;
    } else {
      return false;
    }
  };

  const isEndDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    console.log(today.setHours(0, 0, 0, 0));
    const assignmentEndDate = new Date(date);
    assignmentEndDate?.setHours(0, 0, 0, 0);
    console.log(assignmentEndDate?.setHours(0, 0, 0, 0));
    if (assignmentEndDate >= today) {
      return true;
    } else {
      return false;
    }
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const handleResumeChange = (files) => {
    const fileType = files[0]?.type;

    if (fileType === "application/pdf") {
      setFilePreview(URL.createObjectURL(files[0]));
    } else {
      setFilePreview(null);
    }
  };

  const onAccessSubmit = () => {
    onSubmit(null, "access");
  };

  //Submission of Assignment form
  const onSubmit = (data, type) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let workflow_session = {
      tab_id: formId?.id,
      workflow_id: formId?.WF,
      user_id: userID,
    };
    let user = new FormData();
    user.append("candidate_job_id", candidate_id);
    if (type === "access") {
      user.append("extend_request", true);
    } else {
      user.append("upload_assignment", data?.assignment_sheet);
    }
    user.append("updated_by_id", userID);
    user.append("workflow_session", JSON.stringify(workflow_session));
    user.append("requisition_id", requisition_id);

    const url =
      VARIABLES.url + `/api/assignment/${assignment?.id}/?send_from=rms`;
    console.log(url);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      url: url,
      data: user,
    };

    for (var pair of user.entries()) {
      console.log(pair);
    }

    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          getAssignmentData(candidate_id, requisition_id);
          getWorkflowSession(candidate_id, requisition_id);
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Your response has been sent successfully.",
          });
          nextLevel();
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message:
            error?.response?.data?.message || "Failed to send the response. Please try again.",
        });
      });
  };

  // TAT Submit
  const onAssignmentTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: requisition_id,
      candidate_id: candidate_id,
      stage: "Assignment Upload",
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidatestatus/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const filter_workflow_session = workflowSession.find(
      (api) => api.tab_id == formId?.id
    );
    console.log(filter_workflow_session);
    setWorkflowId(filter_workflow_session);
  }, [workflowSession]);

  useEffect(() => {
    getAssignmentData(candidate_id, requisition_id);
    if (privileges.includes("Assignment Upload")) {
      onAssignmentTATSubmit();
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Assignment Upload </h4>
            {isAssignmentDataLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* {assignment &&
      assignment?.choose_assignment?.assignment_document !== null &&
      isFutureDate(assignment?.start_date) ? ( */}
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4 ">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="profile-ud-item w-100">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">
                        Assignment Title :
                      </span>
                      <span className="profile-ud-value">
                        {assignment?.choose_assignment?.assignment_title || "-"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="profile-ud-item w-100">
                    <div className="profile-ud wider">
                      <span
                        className="profile-ud-label"
                        style={{ width: "200px" }}
                      >
                        Assignment Question Paper :
                      </span>
                      <span className="profile-ud-value">
                        {assignment?.choose_assignment?.assignment_document ? (
                          <a
                            className="btn btn-primary"
                            href={
                              assignment?.choose_assignment?.assignment_document
                            }
                            target="_blank"
                          >
                            <em
                              className="icon ni ni-file-pdf"
                              style={{ color: "white" }}
                            ></em>
                          </a>
                        ) : (
                          <span className="fs-8 text-danger">
                            Assignment not available
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="profile-ud-item w-100">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">Start date :</span>
                      {assignment?.start_date !== null ? (
                        <span className="profile-ud-value">
                          {moment(assignment?.start_date).format(
                            "MMM DD, YYYY h:mm a"
                          )}
                        </span>
                      ) : (
                        <span className="profile-ud-value">-</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="profile-ud-item w-100">
                    <div className="profile-ud wider">
                      <span className="profile-ud-label">End date :</span>
                      {assignment?.end_date !== null ? (
                        <span className="profile-ud-value">
                          {moment(assignment?.end_date).format("MMM DD, YYYY")}
                        </span>
                      ) : (
                        <span className="profile-ud-value">-</span>
                      )}
                    </div>
                  </div>
                </div>

                {!assignment?.upload_assignment && (
                  <>
                    {!isEndDate(assignment?.end_date) ? (
                      <>
                        <div className="col-12">
                          <span className="fs-6 text-danger">
                            The deadline to submit your assignment has passed.
                          </span>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            {submitLoading ? (
                              <button
                                id="button-loading"
                                className="btn text-dark"
                                disabled
                              >
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                id="submit-button-assignassignment"
                                onClick={() => onAccessSubmit()}
                              >
                                Request an extension to submit your assignment.
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label className="form-label">
                          Upload Assignment
                          <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name={`assignment_sheet`}
                          control={control}
                          rules={{
                            required: true,
                            validate: (value) =>
                              value.type === "application/pdf",
                          }}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              className="form-control"
                              id="file-forms-assignment_sheet"
                              type="file"
                              onChange={(e) => {
                                field.onChange(e.target.files[0]);
                                handleResumeChange(e.target.files);
                              }}
                              disabled={isFieldsDisabled}
                            />
                          )}
                        />
                        <div className="d-flex flex-column">
                          {errors.assignment_sheet &&
                            errors.assignment_sheet.type === "required" && (
                              <span className="fs-8 text-danger">
                                Assignment is required
                              </span>
                            )}
                          {errors.assignment_sheet &&
                            errors.assignment_sheet.type === "validate" && (
                              <span className="fs-8 text-danger">
                                Please upload your assignment in pdf format
                              </span>
                            )}
                          {filePreview && (
                            <a
                              className="btn btn-primary mt-2 me-1"
                              href={filePreview}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Preview Uploaded File
                            </a>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {assignment?.upload_assignment && (
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="profile-ud-item w-100">
                      <div className="profile-ud wider">
                        <span
                          className="profile-ud-label"
                          style={{ width: "200px" }}
                        >
                          Your submitted assignment sheet :
                        </span>
                        <span className="profile-ud-value">
                          <a
                            className="btn btn-primary"
                            href={assignment?.upload_assignment}
                            target="_blank"
                          >
                            <em
                              className="icon ni ni-file-pdf"
                              style={{ color: "white" }}
                            ></em>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-12 d-flex">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                  </div>
                  <div className="form-group">
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          workflowId?.is_complete == 1 ? (
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                              onClick={() => nextLevel()}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                            >
                              Next
                            </button>
                          )
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-assignassignment"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Snackbar />
      </div>
      {/* ) : assignment && assignment?.selection_status === null ? (
        <Message
          icon={require("../../../../Resources/images/assignment.png")}
          title={"Please Wait"}
          message={"We will assign you an assignment soon"}
        />
      ) : assignment && assignment?.selection_status !== null ? (
        assignment?.selection_status === 1 ? (
          <Message
            icon={require("../../../../Resources/images/assignment.png")}
            title={"Fantastic Job"}
            message={
              "You've conquered the assignment round with flying colors. Your dedication shines bright as you know closer to becoming a valued member of our team!"
            }
          />
        ) : (
          <Message
            icon={require("../../../../Resources/images/rejected.png")}
            title={"Thank you for your interest!"}
            message={
              "While we won't be moving forward with your application at this time, we were impressed by your skills and encourage you to apply for future opportunities"
            }
          />
        )
      ) : (
        <Message
          icon={require("../../../../Resources/images/assignment.png")}
          title={"Please Wait"}
          message={"We will assign you an assignment soon"}
        />
      )} */}
    </>
  );
};

export default AssignmentUpload;
