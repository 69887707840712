import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { googleLogout } from "@react-oauth/google";
import { ActivityLogsContext } from "../Context/ActivityLogsContext";
import { NotificationsContext } from "../Context/NotificationsContextProvider";
import { VARIABLES } from "../Constants";
import axios from "axios";

const Header = (props) => {
  const email = secureLocalStorage.getItem("email");
  const first_name = secureLocalStorage.getItem("firstName");
  const last_name = secureLocalStorage.getItem("lastName");
  const role = secureLocalStorage.getItem("role");
  console.log(role);
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();

  const [showAllRoles, setShowAllRoles] = useState(false);

  const { handlemenu, rawMenu, isMenuLoading, rawPendingOnYou, getPendingOnYouData, isPendingOnYouLoading } = useContext(ActivityLogsContext);

  const {
    notifications,
    unreadNotificationsCount,
    handleNotificationStatus,
    updateNotificationStatus,
    setUnreadNotificationsCount,
    retryCount,
    getNotifications,
    getAllNotifications,
  } = useContext(NotificationsContext);

  const checkQoutesData = secureLocalStorage.getItem('checkQoutesData');
  console.log(checkQoutesData)

  const [quotesData, setQuotesData] = useState([])
  console.log(quotesData)

  const getQoutes = async () => {
    let url = VARIABLES.url + `/api/quotes`;
    console.log(url)
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await axios(config);
      console.log(res.data.data)
      setQuotesData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const quotesHeader = [
    "Daily Inspiration",
    "Motivational Thought",
    "Positive Vibes",
    "Mindful Moment",
    "Words to Inspire",
    "Your Daily Spark",
    "Inspiration for Today"
  ]

  const [quote, setQuote] = useState("");
  console.log(quote)
  const [quoteHeader, setQuoteHeader] = useState("");

  const [showToast, setShowToast] = useState(true);

  const handleClose = () => {
    setShowToast(false);
  };

  useEffect(() => {
    // Generate a random index
    const randomIndex = Math.floor(Math.random() * quotesData.length);
    console.log("randomIndex: ", randomIndex)
    setQuote(quotesData[randomIndex]);

    const randomQuoteHeaderIndex = Math.floor(Math.random() * quotesHeader.length);
    console.log("randomQuoteHeaderIndex: ", randomQuoteHeaderIndex)
    setQuoteHeader(quotesHeader[randomQuoteHeaderIndex]);
  }, [quotesData]);

  const handleNavigation = (n) => {
    // let hasPrivilege = false;
    if (n.redirection_page_path !== null) {
      // hasPrivilege = n.redirection_page_path.some(element => {
      //   if (privileges.includes(element.privilege)) {
      navigate(n.redirection_page_path[0].path);
      //     return true;
      //   }
      //   return false;
      // });
    } else {
      navigate('*');
    }

    // if (!hasPrivilege) {
    //   navigate('*');
    // }
  };


  // Calling logout function in app.js. State Pull up
  const handleLogout = () => {
    googleLogout();
    props.logoutHandler();
    navigate("/");
  };

  const handleShowAllRoles = () => {
    setShowAllRoles((prev) => !prev);
  };

  const handleBlur = () => {
    setShowAllRoles(false);
  };

  useEffect(() => {
    handlemenu();
  }, []);

  useEffect(() => {
    console.log(retryCount);
    if (retryCount !== 0) {
      // enqueueSnackbar("No connection!", {
      //   anchorOrigin: {
      //     vertical: "bottom",
      //     horizontal: "center",
      //   },
      //   variant: "error",
      //   persist: true,
      //   preventDuplicate: true,
      // });
    } else {
      // closeSnackbar();
    }
  }, [retryCount]);

  useEffect(() => {
    setUnreadNotificationsCount(notifications.length);
  }, [notifications]);

  useEffect(() => {
    getNotifications();
    getAllNotifications();
    if (checkQoutesData === null || checkQoutesData?.length === 0) {
      console.log("first")
      getQoutes();
    }
  }, []);

  useEffect(() => {
    if (quotesData?.length !== 0) {
      secureLocalStorage.setItem('checkQoutesData', quotesData)
      console.log("local")
    }
  }, [quotesData])

  useEffect(() => {
    if (checkQoutesData !== null && checkQoutesData?.length !== 0) {
      console.log("set")
      setQuotesData(checkQoutesData)
    }
  }, [checkQoutesData])

  return (
    <>
      <div className="nk-header is-light nk-header-fixed is-light">
        <div className="container-xl wide-xl">
          <div className="nk-header-wrap">
            <div
              className="nk-menu-trigger d-xl-none ms-n1 me-3"
              id="button-button-sidebar_open"
            >
              <a
                href="javascript:void(0)"
                className="nk-nav-toggle nk-quick-nav-icon"
                data-target="sidebarMenu"
              >
                <em className="icon ni ni-menu"></em>
              </a>
            </div>
            <div className="nk-header-brand d-xl-none bg-blue rounded-3 p-2">
              <img
                src={require("../../Resources/images/RMS-Logo.png")}
                style={{ width: "6rem", maxHeight: "5rem" }}
              />
            </div>
            <div className="d-flex">
              <div className="group">
                <h3 className="nk-block-title page-title">{`Hello, ${first_name[0].toUpperCase() + first_name.slice(1)
                  } ${last_name[0].toUpperCase() + last_name.slice(1)} !`}</h3>
                <p className="py-0 mb-0" style={{ fontWeight: "bold" }}>
                  {/* {role?.map((r, i) => {
                  if (i !== role?.length - 1) {
                    return r.role_name + ", ";
                  }
                  return r.role_name;
                })} */}
                  {role?.reduce((prev, current) => (prev.level < current.level ? prev : current))?.role_name}
                </p>
                <p className="py-0">Check your daily tasks & Schedules</p>
              </div>
            </div>
            <div className="nk-header-tools">

              <ul className="nk-quick-nav">
                {/* PENDING ON YOU */}
                {privileges.includes("pending_on_you") ? (
                  <div className="drodown me-3">
                    <a
                      href="#"
                      className="dropdown-toggle btn btn-primary"
                      data-bs-toggle="dropdown"
                    >
                      <span>Pending On You</span>
                      <em className="dd-indc icon ni ni-chevron-right"></em>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      <ul
                        className="link-list-opt no-bdr"
                        style={{ maxHeight: "300px", overflowY: "auto" }}
                      >
                        {!isPendingOnYouLoading ? (
                          rawPendingOnYou?.length !== 0 ? (
                            rawPendingOnYou?.map((api) => {
                              return (
                                <li className="d-flex align-items-center pe-3">
                                  <a className="dropdown-item" href="#">
                                    {api?.statistics_type}
                                  </a>
                                  <span className="badge bg-outline-gray">
                                    {api?.message}
                                  </span>
                                </li>
                              );
                            })
                          ) : (
                            <>
                              <li className="d-flex align-items-center pe-3">
                                <span className="dropdown-item">No Data</span>
                              </li>
                            </>
                          )
                        ) : (
                          <>
                            <li className="d-flex align-items-center pe-3">
                              <span className="dropdown-item">Loading...</span>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <li className="dropdown language-dropdown d-none d-sm-block me-n1">
                  <a
                    href="#"
                    className="dropdown-toggle nk-quick-nav-icon"
                    data-bs-toggle="dropdown"
                  >
                    <div className="quick-icon">
                      <div className="icon-status icon-status-na">
                        <em className="icon ni ni-view-grid-fill"></em>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <ul
                      className="language-list"
                      style={{ maxHeight: "250px", overflowY: "auto", scrollbarWidth: "thin" }}
                    >
                      {!isMenuLoading ? (
                        rawMenu.length !== 0 ? (
                          rawMenu.map((menu, i) => (
                            <li key={i}>
                              <a href={menu?.app_url} className="language-item" target="_blank">
                                {menu?.logo ? (
                                  <img
                                    src={menu?.logo}
                                    alt=""
                                    className="language-flag"
                                  />
                                ) : (
                                  <div className="user-avatar sm me-2">
                                    <span>
                                      {menu?.name
                                        .charAt(0)
                                        .toUpperCase()}
                                    </span>
                                  </div>
                                )}

                                <span className="language-name">
                                  {menu?.name}
                                </span>
                              </a>
                            </li>
                          ))
                        ) : (
                          <>
                            <li>
                              <p className="language-item">
                                <span className="language-name">No Data</span>
                              </p>
                            </li>
                          </>
                        )
                      ) : (
                        <>
                          <li>
                            <p className="language-item">
                              <span className="language-name">Loading...</span>
                            </p>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </li>

                {/* <li className="me-n1 d-flex align-center justify-content-center">
                  <em className="icon ni ni-bell-fill fs-2 text-info"></em>
                  <a
                    href="#"
                    className="dropdown-toggle nk-quick-nav-icon"
                    data-bs-toggle="dropdown"
                  >
                  </a>
                </li> */}
                {privileges.includes("new_ticket") ? (
                  <div className="dropdown notification-dropdown me-1">
                    <div
                      type="button"
                      className="dropdown-toggle btn-sm position-relative"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      data-bs-auto-close="outside"
                      style={{ fontSize: "1em" }}
                    >
                      <em className="icon ni ni-bell-fill fs-2"></em>
                      {unreadNotificationsCount !== 0 && (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {unreadNotificationsCount <= 99
                            ? unreadNotificationsCount.toString()
                            : "99+"}
                          <span className="visually-hidden">unread messages</span>
                        </span>
                      )}
                    </div>
                    <div className="dropdown-menu dropdown-menu-xl dropdown-menu-end">
                      <div className="dropdown-head">
                        <span className="sub-title nk-dropdown-title">Notifications</span>
                        <span
                          type="button"
                          className="btn btn-sm btn-outline-primary border-0"
                          onClick={updateNotificationStatus}
                        >
                          <em className="icon ni ni-repeat-v"></em>
                        </span>
                      </div>
                      <div className="dropdown-body">
                        <div
                          className="nk-notification"
                          style={{ minHeight: "300px" }}
                        >
                          {/* Dropdown items */}
                          {notifications.length !== 0 ? (
                            notifications.map((n, i) => {
                              return (
                                <div
                                  key={i}
                                  className="nk-notification-item dropdown-inner"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: n?.is_read
                                      ? "#fff"
                                      : "#bddfff",
                                    position: "relative",
                                  }}
                                  onClick={() => {
                                    handleNavigation(n)
                                    handleNotificationStatus(n.id)
                                  }
                                  }
                                >
                                  <div className="nk-notification-content">
                                    <div
                                      className="nk-notification-text"
                                      style={{
                                        fontSize: "1.1em",
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {n.title}
                                    </div>
                                    <div className="nk-notification-text">
                                      {n.message}
                                    </div>
                                    <div className="nk-notification-time">
                                      {moment(new Date(n.created_at)).format(
                                        "MMM DD, YYYY h:mm a"
                                      )}
                                    </div>
                                  </div>
                                  {!n?.is_read && (
                                    <div
                                      style={{
                                        height: "6px",
                                        width: "6px",
                                        borderRadius: "50%",
                                        backgroundColor: "#036ffc",
                                        position: "absolute",
                                        top: "10px",
                                        right: "10px",
                                      }}
                                    ></div>
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <>
                              <div className="nk-notification-item dropdown-inner">
                                <div className="nk-notification-content">
                                  <div className="nk-notification-text text-secondary">
                                    Empty
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="dropdown-foot center">
                        <Link to="/tickets">View All</Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <li
                  className="dropdown user-dropdown"
                  id="button-button-dropdown"
                >
                  <a
                    href="javascript:void(0)"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <div className="user-toggle">
                      <div className="user-avatar sm">
                        <em className="icon ni ni-user-alt"></em>
                      </div>
                    </div>
                  </a>
                  {/* <div
                    className="dropdown-menu dropdown-menu-md dropdown-menu-end"
                    onClick={(e) => e.stopPropagation()}
                  > */}
                  <div
                    className="dropdown-menu dropdown-menu-md dropdown-menu-end"
                    onClick={(e) => e.stopPropagation()}
                    onBlur={handleBlur}
                    tabIndex="0"
                  >
                    <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                      <div
                        className="user-card"
                        style={{ alignItems: "normal" }}
                      >
                        <div className="user-avatar">
                          <span>
                            {first_name?.charAt(0).toUpperCase() +
                              last_name?.charAt(0).toUpperCase()}
                          </span>
                        </div>
                        <div className="user-info">
                          <span className="lead-text">
                            {first_name[0].toUpperCase() +
                              first_name.slice(1) +
                              " " +
                              last_name[0].toUpperCase() +
                              last_name.slice(1)}
                          </span>
                          <span className="sub-text">{email}</span>
                          {role?.length > 1 ? (
                            <>
                              {!showAllRoles && (
                                <span className="lead-text text-info">
                                  {role[0]?.role_name}
                                  <a
                                    className="badge bg-info ms-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleShowAllRoles}
                                  >
                                    +{role?.length - 1}
                                  </a>
                                </span>
                              )}
                              {showAllRoles &&
                                role.map((info) => (
                                  <span className="lead-text text-info">
                                    {info?.role_name}
                                  </span>
                                ))}
                            </>
                          ) : (
                            <span className="lead-text text-info">
                              {role ? role[0]?.role_name : ""}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li id="button-button-signout">
                          <a href="javascript:void(0)" onClick={handleLogout}>
                            <em className="icon ni ni-signout"></em>
                            <span>Sign out</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
              {quote && showToast && (
                <div className="toast"
                  style={{ marginTop: "20px" }}>
                  <div className="toast-header">
                    <strong className="me-auto">{quoteHeader || "Wellness qoute"}</strong>
                    <button type="button" className="btn-close" onClick={handleClose}></button>
                  </div>
                  <div className="toast-body">{quote} </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
