import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { InterviewQuestionContext } from "../../Context/InterviewQuestionMasterContext";
import Swal from "sweetalert2";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

const InterviewQuestionnaireEdit = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const { getInterviewQuestionnair } = useContext(InterviewQuestionContext);

  const {
    isDropdownDataLoading,
    interviewQuestionnaireTypeDropdownData,
    interviewCategoryDropdownData,
  } = useContext(TypemasterDropdownContext);

  const { isGenricDropdownDataLoading, jobOpeningDropdownData } = useContext(GenericDropdownContext);

  const location = useLocation();
  console.log(location.state.editpost);
  const navigate = useNavigate();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [activeStatus, setActiveStatus] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    var user = JSON.stringify({
      job_opening_id: data.job_title.map((val) => val.value),
      interview_type: data.interview_type.map((val) => val.value),
      category: data.category.value,
      question: data.question,
      answer: data.answer,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api/interviewquestionnaire/${location.state?.editpost?.id}/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    console.log(user);

    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        getInterviewQuestionnair();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "successfully updated interview questionnaire",
        });
        reset();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to update interview questionnaire",
          });
        }
      });
  };

  useEffect(() => {
    console.log(location.state?.editpost);
    const editedData = location.state?.editpost;
    if (editedData) {
      var defaultValues = {};
      defaultValues.question = editedData.question;
      defaultValues.answer = editedData.answer;

      const getCategory = {
        value: editedData.category,
        label: editedData.category
      }
      defaultValues.category = getCategory;

      const interviewTypes = editedData.interview_type?.map((info) => ({
        value: info,
        label: info,
      }));

      defaultValues.interview_type = interviewTypes;

      const getJobData = editedData.job_opening?.map((info) => jobOpeningDropdownData?.find((key) => key.id === info.id))

      const getJobTitle = getJobData?.map((info) => ({
        value: info.id,
        label:
          info.job_title +
          " " +
          info.department_name +
          " " +
          info.team_name
      }));
      defaultValues.job_title = getJobTitle;
      reset(defaultValues);
    }
  }, []);

  const deleteInterviewQuestionnaire = (id) => {
    const access = secureLocalStorage.getItem("access");
    const url =
      VARIABLES.url +
      `/api/interviewquestionnaire/${id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };
    axios(config)
      .then((response) => {
        getInterviewQuestionnair();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted interview questionnaire",
        });
        console.log(response.data);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setActiveStatus(0);
          RefreshToken();
        } else {
          setActiveStatus(1);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to delete the interview questionnaire",
          });
        }
      });
  };

  const deleteInterviewQuestionnaireAlert = (id) => {
    setActiveStatus(0);
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInterviewQuestionnaire(id);
      } else {
        setActiveStatus(1);
      }
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Edit Interview Questionnaire
            </h3>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          {privileges.includes("interview_questionnaire_delete") ? (
            <div className="card-inner d-flex justify-content-end pt-3 pb-0">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="submit-button-delete-interviewquestionnaire"
                  checked={activeStatus}
                  onChange={(e) => {
                    deleteInterviewQuestionnaireAlert(
                      location.state?.editpost?.id
                    );
                  }}
                />
                <label
                  className="custom-control-label"
                  id="button-button-status"
                  htmlFor="submit-button-delete-interviewquestionnaire"
                ></label>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Job Title
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={jobOpeningDropdownData?.map((info) => ({
                          value: info.id,
                          label:
                            info.job_title +
                            " " +
                            info.department_name +
                            " " +
                            info.team_name,
                        }))}
                        isLoading={isGenricDropdownDataLoading}
                        isClearable={true}
                        isMulti
                        id="select-forms-jobtitle"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="job_title"
                    control={control}
                  />
                  {errors.job_title && (
                    <span className="fs-8 text-danger">
                      Job title is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interview Type
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={interviewQuestionnaireTypeDropdownData?.map((info) => ({
                          value: info.value,
                          label: info.name,
                        }))}
                        isLoading={isDropdownDataLoading}
                        isClearable={true}
                        isMulti
                        id="select-forms-interviewtype"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="interview_type"
                    control={control}
                  />
                  {errors.interview_type && (
                    <span className="fs-8 text-danger">
                      Interview Type is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Category
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={interviewCategoryDropdownData?.map((info) => ({
                          value: info.value,
                          label: info.name,
                        }))}
                        isLoading={isDropdownDataLoading}
                        isClearable={true}
                        id="select-forms-catogory"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="category"
                    control={control}
                  />
                  {errors.category && (
                    <span className="fs-8 text-danger">
                      Category is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Question <span className="text-danger"> *</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="textarea-forms-question"
                      placeholder="Enter question"
                      {...register("question", {
                        required: true,
                      })}
                    />
                    {errors.question && (
                      <span className="fs-8 text-danger">
                        Question is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Answer <span className="text-danger"> *</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="textarea-forms-answer"
                      placeholder="Enter answer"
                      {...register("answer", {
                        required: true,
                      })}
                    />
                    {errors.answer && (
                      <span className="fs-8 text-danger">
                        Answer is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-editinterviewquestion"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InterviewQuestionnaireEdit;
