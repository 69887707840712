import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { StepperCandidate } from "../../../Context/StepperCandidate";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { useLocation } from "react-router-dom";
import Message from "../Message/Message";
// import { SHFData } from "../../WorkflowStepperForm/StepperFormJson";

const SmartHireFormReview = ({ formId }) => {
  console.log(formId); //data of the tab in which this form is present
  const privileges = secureLocalStorage.getItem("privileges");

  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const { workflowSession, getWorkflowSession } = useContext(WorkflowContext);

  // const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);
  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === formId?.id) ||
    !privileges.includes("Smart Hire Form Review Create");

  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);
  console.log(interviewActionDropdownData);

  const { isSHFLoading,
    SHFData,
    getSHFData } = useContext(StepperCandidate);

  console.log(SHFData);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const location = useLocation();

  const candidate_id = location.state?.candidate_id;
  const requisition_id = location.state?.requisition_id;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [interviewQuestionsWithAnswers, setInterviewQuestionsWithAnswers] = useState([]);
  const [dataWithScores, setDataWithScores] = useState([]);
  console.log(dataWithScores);

  const handleSHFScores = (index, score) => {
    const updatedRowData = [...interviewQuestionsWithAnswers];
    updatedRowData[index] = {
      ...updatedRowData[index],
      score: parseInt(score, 10) || 0,
    };
    setInterviewQuestionsWithAnswers(updatedRowData);
    setDataWithScores(updatedRowData);
  };

  const isValidInput = (value) => {
    return value >= 0 && value <= 5;
  };

  //Submission of form
  const onSubmit = (data) => {
    console.log(data);
    var user = JSON.stringify({
      candidate_interview_id: SHFData[0]?.interview_details?.id,
      candidate_job_id: candidate_id,
      requisition_id: requisition_id,
      question_answers: dataWithScores,
      candidate_status:
        data?.is_selected === "Draft"
          ? 0
          : data?.is_selected === "Accept"
            ? 1
            : data?.is_selected === "Reject"
              ? 2
              : null,
      comments: data?.comments,
      updated_by_id: userID,
      workflow_session: {
        candidate_job_id: candidate_id,
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
        created_by_id: userID,
        updated_by_id: userID,
      },
    });

    console.log(user);

    const url =
      VARIABLES.url +
      `/api/smarthireform/${SHFData[0]?.interview_details?.id}/`;
    console.log(url);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: data?.is_selected === "Draft" ? "Candidate drafted successfully." : data?.is_selected === "Reject" ? "Candidate rejected successfully." : "Candidate accepted successfully.",
          });
          getWorkflowSession(candidate_id, requisition_id);
          nextLevel();
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Failed to review SHF. Please try again.",
        });
      });
  };

  // TAT Submit
  const onSHFTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: requisition_id,
      candidate_id: candidate_id,
      stage: "Smart Hire Form Review",
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidatestatus/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setValue(
      "is_selected",
      SHFData[0]?.interview_details?.candidate_accept_reject === 0
        ? "Draft"
        : SHFData[0]?.interview_details?.candidate_accept_reject === 1
          ? "Accept"
          : SHFData[0]?.interview_details?.candidate_accept_reject === 2
            ? "Reject"
            : null
    );
    setValue("comments", SHFData[0]?.interview_details?.comments);
    SHFData[0]?.interview_question_score?.forEach((_, index) => {
      setValue(`score${index}`, dataWithScores?.[index]?.score);
    });
  }, [SHFData]);

  useEffect(() => {
    if (SHFData?.length > 0) {
      const SHFDataCopy = [...SHFData[0]?.interview_question_score];
      setInterviewQuestionsWithAnswers(SHFDataCopy);
      console.log(SHFDataCopy);
    }
  }, [SHFData]);

  useEffect(() => {
    const updatedRowData = [...interviewQuestionsWithAnswers];
    setDataWithScores(updatedRowData);
  }, [interviewQuestionsWithAnswers]);

  useEffect(() => {
    getSHFData(requisition_id, candidate_id);
    if (privileges.includes("Smart Hire Form Review Create")) {
      onSHFTATSubmit();
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Smart Hire Form Review</h4>
            {isSHFLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isSHFLoading ? (
        ""
      ) : (
        <>
          <div className="col-lg-12 col-md-12 col-sm-12">
            {SHFData[0]?.interview_details?.candidate_accept_reject === 2 ?
              <Message icon={require("../../../../Resources/images/rejected.png")}
                message={"The candidate has been disqualified at this stage of the recruitment process."} />
              :
              <>
                {SHFData[0]?.interview_question_score?.length !== 0 ? (
                  <>
                    <div className="card">
                      <div className="card-inner">
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                          <div className="row g-4 ">
                            {SHFData[0]?.interview_question_score?.map(
                              (post, index) => (
                                <div
                                  className="col-lg-12 col-md-12 col-sm-12"
                                  key={index}
                                >
                                  <div className="form-group">
                                    <label className="form-label">
                                      {post?.question}
                                    </label>
                                    <div className="d-flex">
                                      <textarea
                                        style={{ minHeight: "50px" }}
                                        className="form-control"
                                        value={post?.answer || "-"}
                                        disabled
                                      />
                                      {post?.question !== "Name" &&
                                        post?.question !== "Email" && (
                                          <div className="d-flex flex-column">
                                            <input
                                              type="number"
                                              className="form-control ms-2 w-100"
                                              style={{ width: "20%" }}
                                              placeholder={
                                                post?.answer === ""
                                                  ? "Not answered"
                                                  : "Enter score (out of 5)"
                                              }
                                              {...register(`score${index}`, {
                                                required: post?.answer && SHFData[0]?.interview_details?.candidate_accept_reject === null
                                                  ? "Score is required"
                                                  : false,
                                                validate: {
                                                  isValidInput,
                                                },
                                              })}
                                              onChange={(e) =>
                                                handleSHFScores(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                              onWheel={(e) => e.target.blur()} // Prevents number input scrolling
                                              disabled={!(SHFData[0]?.interview_details?.candidate_accept_reject === null || SHFData[0]?.interview_details?.candidate_accept_reject === 0) || post?.answer === ""}
                                            />
                                            {errors[`score${index}`]?.type === "required" && (
                                              <span
                                                className="ms-3 text-danger"
                                                style={{ fontSize: "10px" }}
                                              >
                                                {errors[`score${index}`]?.message}
                                              </span>
                                            )}
                                            {errors[`score${index}`]?.type ===
                                              "isValidInput" && (
                                                <span
                                                  className="ms-3 text-danger"
                                                  style={{ fontSize: "10px" }}
                                                >
                                                  Input range should be 0 - 5
                                                </span>
                                              )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}

                            <div className="col-lg-6">
                              <div className="form-group">
                                <label className="form-label">Comments</label>
                                <textarea
                                  className="form-control"
                                  {...register("comments")}
                                  disabled={isFieldsDisabled || SHFData[0]?.interview_details?.candidate_accept_reject !== null && SHFData[0]?.interview_details?.candidate_accept_reject !== 0}

                                />
                              </div>
                            </div>

                            <div className="col-lg-6"></div>

                            <div className="col-lg-6 col-md-6 col-sm-12">
                              <ul className="row g-3">
                                {interviewActionDropdownData.map(
                                  (action, index) => (
                                    <li className="col-sm-4" key={index}>
                                      <div className="custom-control custom-radio me-3">
                                        <input
                                          type="radio"
                                          id={`checkbox-form-is-selected-SHF-${action.value.toLowerCase()}`}
                                          className="custom-control-input"
                                          value={action.value}
                                          {...register("is_selected", {
                                            required: true,
                                          })}
                                          disabled={isFieldsDisabled || SHFData[0]?.interview_details?.candidate_accept_reject !== null && SHFData[0]?.interview_details?.candidate_accept_reject !== 0}
                                        />
                                        <label
                                          className="custom-control-label"
                                          for={`checkbox-form-is-selected-SHF-${action.value.toLowerCase()}`}
                                        >
                                          {action.name}
                                        </label>
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
                              {errors.is_selected && (
                                <span className="fs-8 text-danger">
                                  Please select any one
                                </span>
                              )}
                            </div>

                            <div className="col-12 d-flex">
                              <div className="form-group">
                                {level !== 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary me-3"
                                    id="button-button-prevlevel"
                                    onClick={() => prevLevel()}
                                  >
                                    Prev
                                  </button>
                                )}
                              </div>
                              <div className="form-group">
                                {submitLoading ? (
                                  <button className="btn text-dark" disabled>
                                    Loading...
                                  </button>
                                ) : (
                                  <>
                                    {sequenceList.length - 2 > level ? (
                                      <>
                                        {isFieldsDisabled || SHFData[0]?.interview_details?.candidate_accept_reject !== null && SHFData[0]?.interview_details?.candidate_accept_reject !== 0 ? (
                                          <button
                                            type="button"
                                            className="btn btn-lg btn-primary"
                                            id="submit-button-submitshf"
                                            onClick={() => nextLevel()}
                                          >
                                            Next
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            className="btn btn-lg btn-primary"
                                            id="submit-button-submitshf"
                                          >
                                            Next
                                          </button>
                                        )
                                        }
                                      </>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="btn btn-lg btn-primary"
                                        id="submit-button-submitshf"
                                      >
                                        Submit
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    Good job! You are all caught up.
                  </div>
                )}
              </>
            }
          </div>
        </>
      )}

      <Snackbar />
    </>
  );
};

export default SmartHireFormReview;
