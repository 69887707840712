import React from "react";
import { useNavigate } from "react-router-dom";

const PrivilegeNotFound = () => {
    const navigate = useNavigate();
    return (
        <>
            <div class="nk-block nk-block-middle wide-md mx-auto">
                <div class="nk-block-content nk-error-ld text-center">
                    <img
                        class="nk-error-gfx"
                        src={require("../../../Resources/images/fsc_animated_logo_black.gif")}
                        style={{ maxWidth: "10rem", padding: "0px" }}
                    />
                    <div class="wide-xs mx-auto">
                        <h3 class="nk-error-title">Access Denied</h3>
                        <p class="nk-error-text">
                            You do not have privilege to access this page.
                        </p>
                        <a class="btn btn-lg btn-primary mt-2" onClick={() => navigate(-1)}>Back To Home</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivilegeNotFound;
