import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
import { KYCContext } from "../../../Context/KYCContext";
import Snackbar from "../../../Base/Snackbar";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { VARIABLES } from "../../../Constants";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Swal from "sweetalert2";

const KYC = ({ formId }) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;

  const { isAccess } = useContext(RefreshTokenContext);
  console.log(formId); //data of the tab in which this form is present

  // Secure Local storage
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");

  // To get the KYC display names like adhar_card, passport etc.
  const { KYCDocuments } = useContext(TypemasterDropdownContext);
  console.log(KYCDocuments);

  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);

  console.log(workflowSession);

  const { setSubmitMessage } = useContext(SnackbarContext);

  // GET API call of KYC details
  const { KYCDetails, isLoading, getKYCDetails } = useContext(KYCContext);
  console.log(KYCDetails);

  const [submitLoading, setSubmitLoading] = useState(false);

  // Stepper (NEXT and PREV)
  const { level, nextLevel, prevLevel } = useContext(SequenceContext);

  const [KYCCurrData, setKYCCurrData] = useState([]);
  console.log(KYCCurrData);

  const { handleSubmit, control } = useForm();

  const handleFileChange = (file, info) => {
    console.log(file);
    console.log(info);
    const newDocumentData = {
      document_name: info.value,
      document: URL.createObjectURL(file),
    };

    setKYCCurrData((prevDocs) => {
      const updatedDocs = prevDocs.filter(
        (doc) => doc.document_name !== info.value
      );
      return [...updatedDocs, newDocumentData];
    });
    // setValue(info.value, file); // Update the form state
  };

  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);

    const missingDocuments = KYCDocuments
      .filter((doc) => doc.mandatory_field)
      .filter((mandatoryDoc) =>
        !KYCCurrData.some((item) => item.document_name === mandatoryDoc.value)
      )
      .map((doc) => doc.value);

    if (missingDocuments.length > 0) {
      Swal.fire({
        text: `Please upload the following mandatory documents: ${missingDocuments
          .map((doc) => {
            const documentNameMap = {
              photo: "Passport size profile photo",
              aadhar_card: "AADHAR Card",
              pan_card: "PAN Card",
            };
            return documentNameMap[doc] || doc;
          })
          .join(", ")}`,
        confirmButtonText: "Ok",
      }).then(() => {
        console.log("ok")
      });
      return;
    }

    let workflow_session = {
      tab_id: formId?.id,
      workflow_id: formId?.WF,
      user_id: userID,
    };
    let user = new FormData();
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== "")
    );
    Object.keys(filteredData).map((key) => {
      user.append(key, filteredData[key]);
    });
    user.append("candidate_job_id", candidate_id);
    user.append("requisition_id", requisition_id);
    user.append("created_by_id", userID);
    user.append("workflow_session", JSON.stringify(workflow_session));

    for (var pair of user.entries()) {
      console.log(pair);
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      url: VARIABLES.url + `/api/knowyourcandidate/`,
      data: user,
    };
    console.log(config);

    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          getWorkflowSession(candidate_id, requisition_id);
          getKYCDetails(candidate_id, access);
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Documents have been uploaded successfully.",
          });
          nextLevel();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message:
            error?.response?.data?.message || "Failed to upload documents. Please try again.",
        });
      });
  };

  // TAT Submit
  const onKYCTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: requisition_id,
      candidate_id: candidate_id,
      stage: "Know Your Candidate",
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidatestatus/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // To call API once we reached to this tab
  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getKYCDetails(candidate_id, requisition_id, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getKYCDetails(candidate_id, requisition_id, access);
    }
    if (privileges.includes("KYC Create")) {
      onKYCTATSubmit();
    }
  }, [isAccess]);

  // useEffect(() => {
  //   const filter_workflow_session = workflowSession.find(
  //     (api) => api.tab_id == formId?.id
  //   );
  //   console.log(filter_workflow_session);
  //   setWorkflowId(filter_workflow_session);
  // }, [workflowSession]);

  useEffect(() => {
    if (KYCDetails[0]?.documents) {
      setKYCCurrData(KYCDetails[0].documents);
    }
  }, [KYCDetails]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Know Your Candidate </h4>
            {isLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="card">
        {KYCCurrData?.length === 0 && (
          <div
            class="alert alert-danger d-flex align-items-center"
            style={{ margin: "10px", height: "20px" }}
          >
            <em
              className="icon ni ni-alert-circle fs-4 me-2"
              style={{ color: "red" }}
            ></em>
            <div>Documents are not uploaded yet.</div>
          </div>
        )}
        <div className="card-inner">
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row g-4">
              {KYCDocuments?.map((info, index) => {
                const documentData = KYCCurrData?.find(
                  (doc) => doc?.document_name === info?.value
                );
                console.log(documentData);
                return (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <div className="form-control fs-6 d-flex align-center justify-between">
                        <p className="m-0 p-0">{info.name} {info.mandatory_field && <span className="text-danger">*</span>}</p>
                        <div className="d-flex" style={{ gap: "0.5rem" }}>
                          <div>
                            <label
                              className="form-label m-0 p-0"
                              for={`file-upload-${index + 1}`}
                            >
                              <em
                                className="icon ni ni-link-v fs-4"
                                style={{ color: "blue" }}
                              ></em>
                            </label>
                            <Controller
                              name={info.value}
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <input
                                  className="form-control"
                                  id={`file-upload-${index + 1}`}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    field.onChange(e.target.files[0]);
                                    handleFileChange(e.target.files[0], info);
                                  }}
                                  disabled={isFormFilled}
                                />
                              )}
                            />
                          </div>
                          {documentData !== undefined &&
                            documentData?.document !== null ? (
                            <>
                              <div className="align-center">
                                <p className="m-0 p-0">
                                  <a
                                    href={documentData?.document || "-"}
                                    id="button-button-viewdocument"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ color: "black" }}
                                  >
                                    view
                                  </a>
                                </p>
                              </div>
                              <div className="align-center">
                                <em
                                  className="icon ni ni-check-circle fs-4"
                                  style={{ color: "green" }}
                                ></em>
                              </div>
                            </>
                          ) : (
                            <div className="align-center">
                              <em
                                className="icon ni ni-alert-circle fs-4"
                                style={{ color: "red" }}
                              ></em>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="col-12">
                <div className="form-group">
                  {level !== 0 && (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary me-3"
                      id="button-button-prevlevel"
                      onClick={() => prevLevel()}
                    >
                      Prev
                    </button>
                  )}
                  {isFormFilled ? (
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      id="submit-button-submitshf"
                      onClick={() => nextLevel()}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary"
                      id="submit-button-submitshf"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default KYC;
