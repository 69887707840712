import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { VARIABLES } from "../Constants";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "./RefreshTokenContext";
export const TypemasterDropdownContext = createContext(null);

function TypemasterDropdownContextProvider({ children }) {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const [isDropdownDataLoading, setIsDropdownDataLoading] = useState(true);
  console.log(isDropdownDataLoading);
  const [parentCategory, setParentCategory] = useState(null);

  //DROPDOWN STATES
  const [jobTypeDropdownData, setJobTypeDropdownData] = useState([]);
  const [priorityDropdownData, setPriorityDropdownData] = useState([]);
  const [interviewTypeDropdownData, setInterviewTypeDropdownData] = useState([]);
  const [interviewQuestionnaireTypeDropdownData, setInterviewQuestionnaireTypeDropdownData] = useState([]);
  const [interviewModeDropdownData, setInterviewModeDropdownData] = useState([]);
  const [interviewCategoryDropdownData, setInterviewCategoryDropdownData] = useState([]);
  const [agencyTypeDropdownData, setAgencyTypeDropdownData] = useState([]);
  const [parentValueDropdownData, setParentValueDropdownData] = useState([]);
  const [interviewActionDropdownData, setInterviewActionDropdownData] = useState([]);
  const [RE2PortalsDropdownData, setRE2PortalsDropdownData] = useState([]);
  const [RECollegesDropdownData, setRECollegesDropdownData] = useState([]);
  const [KYCDocuments, setKYCDocuments] = useState([]);
  const [requisitionID, setRequisitionID] = useState(null);
  const [requisitionReasonDropdownData, setRequisitionReasonDropdownData] = useState([]);
  const [jobLocationDropdownData, setJobLocationDropdownData] = useState([]);
  const [hiringStageDropdownData, setHiringStageDropdownData] = useState([]);
  const [BGVAgencyDropdownData, setBGVAgencyDropdownData] = useState([]);
  const [StatusTagsDropdownData, setStatusTagsDropdownData] = useState([]);
  const [overallRecommendationDropdownData, setOverallRecommendationDropdownData] = useState([]);
  const [deadlineDropdownData, setDeadlineDropdownData] = useState([]);
  const [otherSourcesDropdownData, setOtherSourcesDropdownData] = useState([]);
  const [teamInchargeDropdownFilterBy, setTeamInchargeDropdownFilterBy] = useState([]);
  const [departmentInchargeDropdownFilterBy, setDepartmentInchargeDropdownFilterBy] = useState([]);
  const [assignSMEDropdownFilterBy, setAssignSMEDropdownFilterBy] = useState([]);
  const [assignRCDropdownFilterBy, setAssignRCDropdownFilterBy] = useState([]);
  const [assignRE1DropdownFilterBy, setAssignRE1DropdownFilterBy] = useState([]);
  const [assignRE2DropdownFilterBy, setAssignRE2DropdownFilterBy] = useState([]);
  const [assignRE3DropdownFilterBy, setAssignRE3DropdownFilterBy] = useState([]);
  const [HRTelephonicInterviewerDropdownFilterBy, setHRTelephonicInterviewerDropdownFilterBy] = useState([]);
  const [HR_TechnicalInterviewerDropdownFilterBy, setHR_TechnicalInterviewerDropdownFilterBy] = useState([]);
  const [interviewerDropdownFilterBy, setInterviewerDropdownFilterBy] = useState([]);

  let dropdownData;
  const getDropdownData = async (access) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/typemasterdetails/",
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      dropdownData = res.data.data;
      setJobTypeDropdownData(dropdownData?.filter((post) => post.category_name == "job_type"));
      setPriorityDropdownData(dropdownData?.filter((post) => post.category_name == "priority"));
      setInterviewTypeDropdownData(dropdownData?.filter((post) => post.category_name == "interview_type"));
      setInterviewQuestionnaireTypeDropdownData(dropdownData?.filter((post) => post.category_name == "interview_questionnaire_type"));
      setInterviewModeDropdownData(dropdownData?.filter((post) => post.category_name == "interview_mode"));
      setInterviewCategoryDropdownData(dropdownData?.filter((post) => post.category_name == "category"));
      setAgencyTypeDropdownData(dropdownData?.filter((post) => post.category_name == "agency_type"));
      setInterviewActionDropdownData(dropdownData?.filter((post) => post.category_name == "interview-action"));
      setRE2PortalsDropdownData(dropdownData?.filter((post) => post.category_name == "re2_portals"));
      setRequisitionID(dropdownData?.filter((post) => post.category_name == "requisition_id"));
      setRECollegesDropdownData(dropdownData?.filter((post) => post.category_name == "re1_college"));
      setKYCDocuments(dropdownData?.filter((post) => post.category_name == "document_name"));
      setRequisitionReasonDropdownData(dropdownData?.filter((post) => post.category_name == "requisition_reason"));
      setJobLocationDropdownData(dropdownData?.filter((post) => post.category_name == "job_location"));
      setHiringStageDropdownData(dropdownData?.filter((post) => post.category_name == "hiring_stage"));
      setBGVAgencyDropdownData(dropdownData?.filter((post) => post.category_name == "bgv_agency"));
      setStatusTagsDropdownData(dropdownData?.filter((post) => post.category_value == "status_tag"));
      setOverallRecommendationDropdownData(dropdownData?.filter((post) => post.category_name == "overall_recommendation"));
      setDeadlineDropdownData(dropdownData?.filter((post) => post.category_value == "requisition_deadline"));
      setOtherSourcesDropdownData(dropdownData?.filter((post) => post.category_value == "other_sources"));
      setTeamInchargeDropdownFilterBy(dropdownData?.filter((post) => post.category_value == "team_incharge"));
      setDepartmentInchargeDropdownFilterBy(dropdownData?.filter((post) => post.category_value == "department_incharge"));
      setAssignSMEDropdownFilterBy(dropdownData?.filter((post) => post.category_value == "assign_sme_dropdown"));
      setAssignRCDropdownFilterBy(dropdownData?.filter((post) => post.category_value == "assign_rc_dropdown"));
      setAssignRE1DropdownFilterBy(dropdownData?.filter((post) => post.category_value == "assign_re1_dropdown"));
      setAssignRE2DropdownFilterBy(dropdownData?.filter((post) => post.category_value == "assign_re2_dropdown"));
      setAssignRE3DropdownFilterBy(dropdownData?.filter((post) => post.category_value == "assign_re3_dropdown"));
      setHRTelephonicInterviewerDropdownFilterBy(dropdownData?.filter((post) => post.category_value == "hr_telephonic_scheduling_interviewer"));
      setHR_TechnicalInterviewerDropdownFilterBy(dropdownData?.filter((post) => post.category_value == "interview_scheduling_interviewer"));
      setInterviewerDropdownFilterBy(dropdownData?.filter((post) => post.category_value == "rc_calender_interviewer_dropdown"));
      setIsDropdownDataLoading(false);
    } catch (error) {
      console.log(error);
      setIsDropdownDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getDropdownData(access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getDropdownData(access);
    }
  }, [isAccess]);

  useEffect(() => {
    console.log("parentCategory: ", parentCategory);
    console.log("parentCategory: ", parentCategory);
    setParentValueDropdownData(
      dropdownData?.filter((post) => post.category_name == { parentCategory })
    );
  }, [parentCategory]);

  const value = {};
  return (
    <div>
      <TypemasterDropdownContext.Provider
        value={{
          isDropdownDataLoading,
          setParentCategory,

          getDropdownData,

          jobTypeDropdownData,
          priorityDropdownData,
          interviewTypeDropdownData,
          interviewQuestionnaireTypeDropdownData,
          interviewModeDropdownData,
          interviewCategoryDropdownData,
          agencyTypeDropdownData,
          parentValueDropdownData,
          interviewActionDropdownData,
          RE2PortalsDropdownData,
          RECollegesDropdownData,
          KYCDocuments,
          requisitionID,
          requisitionReasonDropdownData,
          jobLocationDropdownData,
          hiringStageDropdownData,
          BGVAgencyDropdownData,
          StatusTagsDropdownData,
          overallRecommendationDropdownData,
          deadlineDropdownData,
          otherSourcesDropdownData,
          teamInchargeDropdownFilterBy,
          departmentInchargeDropdownFilterBy,
          assignSMEDropdownFilterBy,
          assignRCDropdownFilterBy,
          assignRE1DropdownFilterBy,
          assignRE2DropdownFilterBy,
          assignRE3DropdownFilterBy,
          HRTelephonicInterviewerDropdownFilterBy,
          HR_TechnicalInterviewerDropdownFilterBy,
          interviewerDropdownFilterBy
        }}
      >
        {children}
      </TypemasterDropdownContext.Provider>
    </div>
  );
}

export default TypemasterDropdownContextProvider;
