import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { VARIABLES } from "../Constants";
import { RefreshTokenContext } from "./RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";
export const GenericDropdownContext = createContext(null);

function GenericDropdownContextProvider({ children }) {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");

  const [isGenricDropdownDataLoading, setisGenricDropdownDataLoading] = useState(true);
  const [isGenricParamsDropdownDataLoading, setisGenricParamsDropdownDataLoading] = useState(true);

  const [isInterviewersDropdownDataLoading, setisInterviewersDropdownDataLoading] = useState(true);

  const [isCandidateDropdownDataLoading, setisCandidateDropdownDataLoading] = useState(true);

  //GENERIC DROPDOWN STATES
  const [departmentDropdownData, setDepartmentDropdownData] = useState([]);
  const [teamDropdownData, setTeamDropdownData] = useState([]);
  const [positionDropdownData, setPositionDropdownData] = useState([]);
  const [seniorityDropdownData, setSeniorityDropdownData] = useState([]);
  const [roleDropdownData, setRoleDropdownData] = useState([]);
  const [formsDropdownData, setFormsDropdownData] = useState([]);
  const [jobOpeningDropdownData, setJobOpeningDropdownData] = useState([]);
  const [workflowDropdownData, setWorkflowDropdownData] = useState([]);
  const [tagCategoryDropdownData, setTagCategoryDropdownData] = useState([]);
  const [typeMasterCategoryDropdownData, setTypeMasterCategoryDropdownData] = useState([]);
  const [jobAssignmentDropdownData, setJobAssignmentDropdownData] = useState([]);
  console.log("jobAssignmentDropdownData: ", jobAssignmentDropdownData)
  const [usersDropdownData, setUsersDropdownData] = useState([]);
  const [agencyNameDropdownData, setAgencyNameDropdownData] = useState([]);
  const [jobTitleDropdownData, setJobTitleDropdownData] = useState([]);

  const [interviewersDropdownData, setInterviewersDropdownData] = useState([]);
  const [interviewersSMECalendarDropdownData, setInterviewersSMECalendarDropdownData] = useState([]);
  const [candidateDropdownData, setCandidateDropdownData] = useState([]);

  const getGenericDropdownData = async (access) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/generic/",
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      setisGenricDropdownDataLoading(false);
      setDepartmentDropdownData(res.data.department);
      setTeamDropdownData(res.data.team);
      setPositionDropdownData(res.data.position);
      setSeniorityDropdownData(res.data.seniority);
      setRoleDropdownData(res.data.roles);
      setFormsDropdownData(res.data.forms);
      setJobOpeningDropdownData(res.data.job_opening);
      setWorkflowDropdownData(res.data.workflow);
      setTagCategoryDropdownData(res.data.tag_category);
      setTypeMasterCategoryDropdownData(res.data.type_master_category);
      setJobAssignmentDropdownData(res.data.job_assignment);
      setAgencyNameDropdownData(res.data.agency);
      setJobTitleDropdownData(res.data.requisition);
    } catch (error) {
      console.log(error);
      setisGenricDropdownDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const getGenericParamsDropdownData = async (access) => {
    let url =
      VARIABLES.url + `/api/generic/?login_types_id=${VARIABLES.rms_id}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      setUsersDropdownData(res.data.user);
      setisGenricParamsDropdownDataLoading(false);
    } catch (error) {
      console.log(error);
      setisGenricParamsDropdownDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const getInterviewersDropdownData = async (reqID, access) => {
    const requisition = reqID?.map((api) => api.value);
    let url =
      VARIABLES.url + `/api/get-interviewer/?requisition_id=${requisition}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      setInterviewersDropdownData(res.data.data);
      setisInterviewersDropdownDataLoading(false);
    } catch (error) {
      console.log(error);
      setisInterviewersDropdownDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const getInterviewersSMECalendarDropdownData = async (access) => {
    let url = VARIABLES.url + `/api/get-interviewer/?user_id=${userID}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      setInterviewersSMECalendarDropdownData(res.data.data);
      setisInterviewersDropdownDataLoading(false);
    } catch (error) {
      console.log(error);
      setisInterviewersDropdownDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const getCandidateDropdownData = async (reqID, interviewer_id, access) => {
    const requisition = reqID?.map((api) => api.value);
    const interviewer = interviewer_id?.map((api) => api.value);
    let url =
      VARIABLES.url + `/api/get-candidates/?requisition_id=${requisition}&interviewer_id=${interviewer}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      setCandidateDropdownData(res.data.data);
      setisCandidateDropdownDataLoading(false);
    } catch (error) {
      console.log(error);
      setisCandidateDropdownDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getGenericDropdownData(access);
      getGenericParamsDropdownData(access);
      getInterviewersSMECalendarDropdownData(access)
    } else {
      let access = secureLocalStorage.getItem("access");
      getGenericDropdownData(access);
      getGenericParamsDropdownData(access);
      getInterviewersSMECalendarDropdownData(access)
    }
  }, [isAccess]);

  return (
    <div>
      <GenericDropdownContext.Provider
        value={{
          isGenricDropdownDataLoading,
          isGenricParamsDropdownDataLoading,
          isInterviewersDropdownDataLoading,
          isCandidateDropdownDataLoading,
          getGenericDropdownData,
          getGenericParamsDropdownData,
          getInterviewersDropdownData,
          getCandidateDropdownData,

          departmentDropdownData,
          teamDropdownData,
          positionDropdownData,
          seniorityDropdownData,
          roleDropdownData,
          formsDropdownData,
          jobOpeningDropdownData,
          workflowDropdownData,
          tagCategoryDropdownData,
          typeMasterCategoryDropdownData,
          jobAssignmentDropdownData,
          agencyNameDropdownData,
          jobTitleDropdownData,

          usersDropdownData,
          interviewersDropdownData,
          candidateDropdownData,
          interviewersSMECalendarDropdownData
        }}
      >
        {children}
      </GenericDropdownContext.Provider>
    </div>
  );
}

export default GenericDropdownContextProvider;
