import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import Select from "react-select";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import { BackgroundVerificationContext } from "../../../Context/BackgroundVerificationContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import Snackbar from "../../../Base/Snackbar";
import { useLocation } from "react-router-dom";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";

const BackgroundVerificationForm = (props) => {
  const location = useLocation();
  const candidate_id = location?.state?.candidate_id;
  const requisition_id = location?.state?.requisition_id;
  const job_opening_id = location?.state?.job_opening_id;

  console.log(props.formId); //data of the tab in which this form is present

  //message after submitting data
  const { setSubmitMessage } = useContext(SnackbarContext);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [uploadedFileURL, setUploadedFileURL] = useState(null);
  const [fileName, setFileName] = useState("");
  console.log("fileName: ", fileName)

  // Local storage
  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const privileges = secureLocalStorage.getItem("privileges");
  // const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  // console.log(candidate_job_id);
  // const requisition_id = secureLocalStorage.getItem("RWFrequisition_id");

  // stores the background verification in "bgVerificationDataOne", "bgVerificationDataTwo","bgVerificationDataThree"

  const {
    isLoading,
    bgVerificationDataOne,
    bgVerificationDataTwo,
    bgVerificationDataThree,
    getBGVerificationData,
  } = useContext(BackgroundVerificationContext);
  console.log(bgVerificationDataOne);
  console.log(bgVerificationDataTwo);
  console.log(bgVerificationDataThree);

  const { isDropdownDataLoading, BGVAgencyDropdownData } = useContext(
    TypemasterDropdownContext
  );
  console.log(BGVAgencyDropdownData);

  // Stores then background verification data according to the type wise
  const bgVerificationData =
    props.bgv_type === "bgv_1" && bgVerificationDataOne?.length > 0
      ? bgVerificationDataOne[0]
      : props.bgv_type === "bgv_2" && bgVerificationDataTwo?.length > 0
        ? bgVerificationDataTwo[0]
        : props.bgv_type === "bgv_3" && bgVerificationDataThree?.length > 0
          ? bgVerificationDataThree[0]
          : null;
  console.log("bgVerificationData:", bgVerificationData);

  // To call workflow session after the POST/ PATCH request
  const { getWorkflowSession, workflowSession } = useContext(WorkflowContext);

  const [isEditable, setIsEditable] = useState(bgVerificationData !== null && bgVerificationData?.length !== 0);

  const getPrivilegeForBgvType = (bgvType) => {
    switch (bgvType) {
      case "bgv_1":
        return "Baground Verification one Create";
      case "bgv_2":
        return "Background Verification two Create";
      case "bgv_3":
        return "Background Verification three Create";
      default:
        return "";
    }
  };

  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === props.formId?.id) ||
    !privileges.includes(getPrivilegeForBgvType(props.bgv_type));

  // React Hook form
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = useForm();

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleFormSubmit = (data) => {
    if (isDirty) {
      handleSubmit(onSubmit)(data);
    } else {
      nextLevel();
    }
  };

  // Stepper (PREV/NEXT)
  const { level, sequenceList, nextLevel, prevLevel } =
    useContext(SequenceContext);

  //Submission of form
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    let workflow_session = {
      tab_id: props.formId?.id,
      workflow_id: props.formId?.WF,
      user_id: userID,
    };
    let user = new FormData();
    user.append("candidate_job_id", candidate_id);
    user.append("requisition_id", requisition_id);
    user.append("verification_agency", data.verification_agency?.value);
    user.append("date_of_verification", data.date_of_verification);
    user.append("comments", data.comments);
    user.append("bgv_type", props.bgv_type);
    user.append("result", data.document);
    user.append("created_by_id", userID);
    user.append("workflow_session", JSON.stringify(workflow_session));
    // if (bgVerificationData == null) {
    // }else {
    // user.append("updated_by_id", userID);
    // }

    // if (bgVerificationData !== null && bgVerificationData) {
    //   const url = VARIABLES.url + `/api/backgroundverification/${bgVerificationData?.id}/`;
    //   console.log(url);

    //   var config = {
    //     method: "patch",
    //     maxBodyLength: Infinity,
    //     headers: {
    //       "Authorization": `Bearer ${access}`
    //     },
    //     url: url,
    //     data: user,
    //   };
    // } else {
    const url = VARIABLES.url + `/api/backgroundverification/`;
    console.log(url);
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      url: url,
      data: user,
    };
    // }

    for (var pair of user.entries()) {
      console.log(pair);
    }

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          getBGVerificationData(candidate_id, props.bgv_type);
          getWorkflowSession(candidate_id, requisition_id);
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: "Background verification has been submitted successfully.",
          });
          nextLevel();
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Background verification submission failed. Please try again.",
        });
      });
  };

  // TAT Submit
  const onBGVTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: requisition_id,
      candidate_id: candidate_id,
      stage: props.bgv_type === "bgv_1"
        ? "Background Verification One"
        : props.bgv_type === "bgv_2"
          ? "Background Verification Two"
          : props.bgv_type === "bgv_3"
            ? "Background Verification Three"
            : null,
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidatestatus/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Pre-populting Background Verification data
  useEffect(() => {
    const editedData = bgVerificationData;
    console.log(editedData);
    if (editedData) {
      var defaultValues = {};
      let filename = editedData.result.split("/").pop();
      if (filename.length > 30) {
        filename = filename.substring(0, 27) + "...";
      }
      setFileName(filename);
      defaultValues.comments = editedData.comments;
      const getAssignment = {
        value: editedData?.verification_agency,
        label: editedData?.verification_agency,
      };
      defaultValues.verification_agency = getAssignment;
      if (editedData.date_of_verification) {
        const verificationDate = new Date(editedData.date_of_verification);
        const localDate = new Date(
          verificationDate.getTime() -
          verificationDate.getTimezoneOffset() * 60000
        );
        const formattedDeadline = localDate.toISOString().slice(0, 16);
        defaultValues.date_of_verification = formattedDeadline;
      }
      reset(defaultValues);
    }
  }, [bgVerificationData]);

  const [workflowId, setWorkflowId] = useState(null);
  console.log(workflowSession);

  useEffect(() => {
    const filter_workflow_session = workflowSession.find(
      (api) => api.tab_id == props.formId?.id
    );
    console.log(filter_workflow_session);
    setWorkflowId(filter_workflow_session);
  }, [workflowSession]);

  useEffect(() => {
    if (privileges.includes(getPrivilegeForBgvType(props.bgv_type))) {
      onBGVTATSubmit();
    }
  }, [])

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title"> Background Verification </h4>
            {isLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("rc_update_provision") && (
            <div className="nk-block-head-content pe-2">
              {bgVerificationData && bgVerificationData?.length !== 0 && (
                <>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={toggleEdit}
                    >
                      {isEditable ? "Cancel" : "Edit"}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {props.formId?.is_iterable && props.formId?.is_iterable == true && (
            <AddStepperTab formId={props.formId} />
          )}
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Verification Agency
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={BGVAgencyDropdownData?.map((assignment) => ({
                          label: assignment?.name,
                          value: assignment?.name,
                        }))}
                        isLoading={isDropdownDataLoading}
                        isClearable={true}
                        id="select-forms-verification_agency"
                        isDisabled={isFieldsDisabled}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="verification_agency"
                    control={control}
                  />
                  {errors.verification_agency && (
                    <span className="fs-8 text-danger">
                      Verification Agency is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Date of Verification
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="form-control"
                      id="date-forms-date_of_verification"
                      placeholder="Enter Verification date"
                      {...register("date_of_verification", { required: true })}
                      disabled={isFieldsDisabled}
                    />
                    {errors.date_of_verification && (
                      <span className="text-danger">
                        Verification date is required
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-6 col-sm-12"
                  style={{ marginTop: "55px" }}
                >
                  <div className="form-group">
                    <div className="form-control fs-6 d-flex align-center justify-between">
                      <p className="m-0 p-0">
                        {fileName !== "" ? fileName : <>Upload Document <span className="text-danger">*</span></>}
                      </p>
                      <div
                        className="d-flex align-center"
                        style={{ gap: "0.5rem" }}
                      >
                        {!isFieldsDisabled && (
                          <div>
                            <label
                              className="form-label m-0 p-0"
                              for="file-forms-document"
                            >
                              <em
                                className="icon ni ni-link-v fs-4"
                                style={{ color: "blue" }}
                              ></em>
                            </label>
                            <Controller
                              name="document"
                              control={control}
                              rules={{
                                required: true,
                                validate: (value) => value.type === "application/pdf",
                              }}
                              defaultValue=""
                              render={({ field }) => (
                                <input
                                  className="form-control"
                                  id="file-forms-document"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      const fileURL = URL.createObjectURL(file);
                                      setUploadedFileURL(fileURL);
                                      field.onChange(file);
                                    }
                                  }}
                                  disabled={isFieldsDisabled}
                                />
                              )}
                            />
                            {errors.document && errors.document.type === "required" && (
                              <span className="fs-8 text-danger">Document is required</span>
                            )}
                            {errors.document && errors.document.type === "validate" && (
                              <span className="fs-8 text-danger">
                                Please upload your document in pdf format
                              </span>
                            )}
                          </div>
                        )}
                        {uploadedFileURL ||
                          (bgVerificationData && bgVerificationData.result) ? (
                          <>
                            <div className="align-center">
                              <p className="m-0 p-0">
                                <a
                                  href={
                                    uploadedFileURL || bgVerificationData.result
                                  }
                                  id="button-button-viewdocument"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ color: "black" }}
                                >
                                  view
                                </a>
                              </p>
                            </div>
                            <div className="align-center">
                              <em
                                className="icon ni ni-check-circle fs-4"
                                style={{ color: "green" }}
                              ></em>
                            </div>
                          </>
                        ) : (
                          <div className="align-center">
                            <em
                              className="icon ni ni-alert-circle fs-4"
                              style={{ color: "red" }}
                            ></em>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Upload Document <span className="fs-8 text-danger">*</span>
                  </label>
                  <Controller
                    name="document"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className="form-control"
                        id="file-forms-document"
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                        disabled={!isEditable}
                      />
                    )}
                  />
                  <div className="d-flex flex-column">
                    {errors.document && errors.document.type === "required" && (
                      <span className="fs-8 text-danger">
                        Document is required
                      </span>
                    )}
                    {bgVerificationData?.result && (
                      <span className="fs-8 pt-2">
                        Previously uploaded document :{" "}
                        <a
                          className="btn btn-primary"
                          id="button-button-viewresume"
                          href={bgVerificationData?.result}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <em
                            className="icon ni ni-file-pdf"
                            style={{ color: "white" }}
                          ></em>
                        </a>
                      </span>
                    )}
                  </div>
                </div> */}

                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">Comments</label>
                    <input
                      type="text"
                      className="form-control"
                      id="text-forms-comments"
                      placeholder="Enter comments"
                      {...register("comments")}
                      disabled={isFieldsDisabled}
                    />
                  </div>
                </div>

                <div className="col-12 d-flex">
                  <div className="form-group">
                    {level !== 0 && (
                      <button
                        type="button"
                        className="btn btn-lg btn-primary me-3"
                        id="button-button-prevlevel"
                        onClick={() => prevLevel()}
                      >
                        Prev
                      </button>
                    )}
                  </div>
                  <div className="form-group">
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <>
                        {sequenceList.length - 2 > level ? (
                          workflowId?.is_complete === 1 ? (
                            <button
                              type="button"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                              onClick={() => nextLevel()}
                            >
                              Next
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-lg btn-primary"
                              id="submit-button-assignassignment"
                            >
                              Next
                            </button>
                          )
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            id="submit-button-assignassignment"
                          >
                            Submit
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default BackgroundVerificationForm;
