import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { pdfjs } from "react-pdf";

import reportWebVitals from "./reportWebVitals";
import LoginContextProvider from "./Components/Context/LoginContext";
import JobOpeningProvider from "./Components/Context/JobOpeningContext";
import RequestedJobOpeningProvider from "./Components/Context/RequestedJobOpeningContext";
import TAMRE1RE2RE3Provider from "./Components/Context/TAMRE1RE2RE3Context";
import CandidateDetailsProvider from "./Components/Context/CandidateDetailsContext";
import InterviewQuestionContextProvider from "./Components/Context/InterviewQuestionMasterContext";
import MDApprovalProvider from "./Components/Context/MDApprovalContext";
import SequenceContextProvider from "./Components/Pages/WorkflowStepperForm/SequenceContextProvider";
import UserDetailsProvider from "./Components/Context/UserDetailsContext";
import JobAssignmentProvider from "./Components/Context/JobAssignment";
import WorkflowProvider from "./Components/Context/WorkflowContext";
import SettingContextProvider from "./Components/Context/SettingsContext";
import SendOfferContextProvider from "./Components/Context/SendOfferContext";
import TechnicalRoundProvider from "./Components/Context/TechnicalRoundContext";
import ScheduleInterviewProvider from "./Components/Context/ScheduleInterviewContext";
import StepperCandidateProvider from "./Components/Context/StepperCandidate";
import MasterTableProvider from "./Components/Context/MasterTableContext";
import CandidateResumeProvider from "./Components/Context/CandidateResumeContext";
import BackgroundVerificationContextProvider from "./Components/Context/BackgroundVerificationContext";
import TagProvider from "./Components/Context/TagContext";
import ViewResumeProvider from "./Components/Context/ViewResumeContext";
import GenericDropdownContextProvider from "./Components/Context/GenericDropdownContext";
import TypemasterDropdownContextProvider from "./Components/Context/TypemasterDropdownContext";
import ScheduleCandidateInterviewProvider from "./Components/Context/ScheduleCandidateInterviewContext";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ResumeAdvanceFilterProvider from "./Components/Context/ResumeAdvanceFilter";
import ResumeDownloadProvider from "./Components/Context/ResumeDownloadContext";
import KYCContextProvider from "./Components/Context/KYCContext";
import ActivityLogsProvider from "./Components/Context/ActivityLogsContext";
import SnackbarContextProvider from "./Components/Context/SnackbarContext";
import RefreshTokenProvider from "./Components/Context/RefreshTokenContext";
import NotificationsContextProvider from "./Components/Context/NotificationsContextProvider";
import CandidateEntriesProvider from "./Components/Context/CandidateEntriesContext";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="300240498448-lr4bomrhpqrso0c1eb9un8q9bo5v44q3.apps.googleusercontent.com">
    <React.StrictMode>
      <BrowserRouter basename="/">
        <RefreshTokenProvider>
          <JobOpeningProvider>
            <LoginContextProvider>
              <RequestedJobOpeningProvider>
                <TAMRE1RE2RE3Provider>
                  <CandidateDetailsProvider>
                    <UserDetailsProvider>
                      <InterviewQuestionContextProvider>
                        <MDApprovalProvider>
                          <JobAssignmentProvider>
                            <SendOfferContextProvider>
                              <SettingContextProvider>
                                <WorkflowProvider>
                                  <SequenceContextProvider>
                                    <TechnicalRoundProvider>
                                      <ScheduleInterviewProvider>
                                        <StepperCandidateProvider>
                                          <CandidateResumeProvider>
                                            <BackgroundVerificationContextProvider>
                                              <TagProvider>
                                                <ViewResumeProvider>
                                                  <TypemasterDropdownContextProvider>
                                                    <GenericDropdownContextProvider>
                                                      <ScheduleCandidateInterviewProvider>
                                                        <ResumeAdvanceFilterProvider>
                                                          <ResumeDownloadProvider>
                                                            <KYCContextProvider>
                                                              <ActivityLogsProvider>
                                                                <SnackbarContextProvider>
                                                                  <MasterTableProvider>
                                                                    <NotificationsContextProvider>
                                                                      <CandidateEntriesProvider>
                                                                        <App />
                                                                      </CandidateEntriesProvider>
                                                                    </NotificationsContextProvider>
                                                                  </MasterTableProvider>
                                                                </SnackbarContextProvider>
                                                              </ActivityLogsProvider>
                                                            </KYCContextProvider>
                                                          </ResumeDownloadProvider>
                                                        </ResumeAdvanceFilterProvider>
                                                      </ScheduleCandidateInterviewProvider>
                                                    </GenericDropdownContextProvider>
                                                  </TypemasterDropdownContextProvider>
                                                </ViewResumeProvider>
                                              </TagProvider>
                                            </BackgroundVerificationContextProvider>
                                          </CandidateResumeProvider>
                                        </StepperCandidateProvider>
                                      </ScheduleInterviewProvider>
                                    </TechnicalRoundProvider>
                                  </SequenceContextProvider>
                                </WorkflowProvider>
                              </SettingContextProvider>
                            </SendOfferContextProvider>
                          </JobAssignmentProvider>
                        </MDApprovalProvider>
                      </InterviewQuestionContextProvider>
                    </UserDetailsProvider>
                  </CandidateDetailsProvider>
                </TAMRE1RE2RE3Provider>
              </RequestedJobOpeningProvider>
            </LoginContextProvider>
          </JobOpeningProvider>
        </RefreshTokenProvider>
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

reportWebVitals();
