import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import { ResumeAdvanceFilterContext } from "../../Context/ResumeAdvanceFilter";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import { NavLink, useLocation } from "react-router-dom";
import { JobOpeningContext } from "../../Context/JobOpeningContext";
import Swal from "sweetalert2";

const ResumePoolAdvanceFilter = () => {
  const { reset } = useForm();
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");
  const { RefreshToken, isAccess } = useContext(RefreshTokenContext);
  const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isSelect, setIsSelect] = useState([]);
  console.log(isSelect)
  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchRequest, setSearchRequest] = useState("");
  let location = useLocation();
  console.log(location)

  const {
    shortlistedResumeData,
    getAdvanceResumeFilterData,
    resumeEntries,
    resumePageCount,
    setResumeEntries,
    resumeCount,
    handleResumeClick,
    isData,
    isLoading,
    setSelectedPlatform,
    setSelectedStatus,
    selectedPlatform,
    selectedStatus,
    setSelectedDate,
    selectedDate,
    setSelectedStage,
    selectedStage,
    setSelectedJobOpening,
    selectedJobOpening,
    setSelectedRequisition,
    selectedRequisition,
    setShowSelectedDate,
    showSelectedDate
  } = useContext(ResumeAdvanceFilterContext);

  console.log("selectedStatus: ", selectedStatus);
  console.log("showSelectedDate: ", showSelectedDate);

  const {
    agencyNameDropdownData,
    jobOpeningDropdownData,
    isGenricDropdownDataLoading,
  } = useContext(GenericDropdownContext);
  const {
    RECollegesDropdownData,
    RE2PortalsDropdownData,
    requisitionID,
    hiringStageDropdownData,
    StatusTagsDropdownData,
    otherSourcesDropdownData
  } = useContext(TypemasterDropdownContext);

  const { rawMapRequisition, isMapRequisitionLoading, getMapRequisitionData } = useContext(JobOpeningContext)

  console.log(hiringStageDropdownData);
  console.log(StatusTagsDropdownData);

  const access = secureLocalStorage.getItem("access");

  // Agency Options in dropdown
  const agency_name = agencyNameDropdownData.map((api) => {
    return { value: api.agency_name, label: api.agency_name };
  });

  // RE1 College Options in dropdown
  const re1_college = RECollegesDropdownData.map((api) => {
    return { value: api.value, label: api.name };
  });

  // RE2 Portal Options in dropdown
  const re2_portals = RE2PortalsDropdownData.map((api) => {
    return { value: api.value, label: api.name };
  });

  // Other Sources Options in dropdown
  const other_sources = otherSourcesDropdownData.map((api) => {
    return { value: api.value, label: api.name };
  });

  // Combine agency, college and portal save in single variable as "combine_agency_college_portals"
  const combine_agency_college_portals = [
    ...agency_name,
    ...re1_college,
    ...re2_portals,
    ...other_sources
  ];

  // Options of Status filter
  const combine_status = StatusTagsDropdownData.map((api) => {
    return { value: api.value, label: api.name };
  });
  console.log("combine_status: ", combine_status);
  // handlePlatformChange => Stores the selected {label,value} in "selectedPlatform" state
  const handlePlatformChange = (selectedOption) => {
    setSelectedPlatform(selectedOption);
    setIsSelect([]);
  };

  // handleStatusChange => Stores the selected {label,value} in "selectedStatus" state
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setIsSelect([]);
  };

  // handleStageChange => Stores the selected {label,value} in "selectedStage" state
  const handleStageChange = (selectedOption) => {
    console.log("first")
    setSelectedStage(selectedOption);
    setIsSelect([]);
  };

  // handleJobOpeningChange => Stores the selected {label,value} in "selectedStage" state
  const handleJobOpeningChange = (selectedOption) => {
    setSelectedJobOpening(selectedOption);
    setIsSelect([]);
  };

  // handleRequisitionChange => Stores the selected {label,value} in "selectedStage" state
  const handleRequisitionChange = (selectedOption) => {
    setSelectedRequisition(selectedOption);
    setIsSelect([]);
  };

  const handleEntries = (event) => {
    setResumeEntries(event.target.value);
  };

  const handleHeaderCheckboxChange = (event) => {
    setIsCheckboxClicked(event.target.checked);
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setIsSelect(shortlistedResumeData.map((api) => api.id));
    if (selectAll) {
      setIsSelect([]);
    }
  };

  const handleClick = (event, id) => {
    const checked = event.target.checked;
    if (checked) {
      setIsSelect((prevSelected) => [...prevSelected, id]);
    } else {
      setIsSelect((prevSelected) => prevSelected.filter((item) => item !== id));
    }

    setIsCheckboxClicked(checked || isSelect.length > 1);
  };

  const [requisition, setRequisition] = useState([]);
  console.log(requisition)

  const handleRequsiition = (selectedOption) => {
    setRequisition(selectedOption);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    setIsSelect([]);
    if (method === "search") {
      getAdvanceResumeFilterData(1, searchRequest);
    } else {
      getAdvanceResumeFilterData(1);
      setSearchRequest("");
    }
  };

  const mapRequisitionToResume = () => {
    if (requisition?.length === 0) {
      Swal.fire({
        text: `Kindly select a requisition to map the candidate.`,
        confirmButtonText: "Okay",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('okay')
        }
      });
      return;
    }
    const access = secureLocalStorage.getItem("access");
    let user = new FormData();
    user.append("resume_id", isSelect.map((api) => api));
    user.append("requisition_id", requisition.map((api) => api.value));
    user.append("created_by_id", userID);

    if (location.state?.from === 'internally') {
      user.append("manage_internally", `${location.state?.type}-MI`);
    }

    for (var pair of user.entries()) {
      console.log(pair);
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/advanced-search/",
      headers: {
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        reset();
        setSubmitLoading(false);
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Resume Mapped With the Requisiiton",
        });
        setIsSelect([]);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: `${error.response?.data?.message || "Failed to map the requisition with resume"}`,
          });
        }
        console.log(error);
      });
  };

  const handleDate = (event) => {
    console.log(event.target.value);
    if (event.target.value !== '') {
      console.log("done");
      const selected_date = new Date(event.target.value);
      const formattedDate = selected_date.toLocaleDateString("en-GB");
      setSelectedDate(formattedDate);
      setShowSelectedDate(event.target.value);
    } else {
      setSelectedDate(null);
      setShowSelectedDate(null);
    }
  };

  const formatName = (name) => {
    if (!name) {
      return "-";
    } else {
      const parts = name.split(" ");
      console.log(parts);
      const firstName = parts[0]
        ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
        : "";
      const lastName = parts[1]
        ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
        : "";
      return `${firstName} ${lastName}`;
    }
  };

  useEffect(() => {
    let access = secureLocalStorage.getItem("access");
    getAdvanceResumeFilterData(1, searchRequest, access);
  }, [
    resumeEntries,
    selectedPlatform,
    selectedStatus,
    isAccess,
    selectedDate,
    selectedStage,
    selectedJobOpening,
    selectedRequisition
  ]);

  // useEffect(() => {
  //   setSearchRequest("");
  // }, [selectedPlatform, selectedStatus, selectedStage, selectedJobOpening, selectedRequisition]);

  useEffect(() => {
    getMapRequisitionData(userID, access);
  }, [])


  useEffect(() => {
    if (location.state?.from === 'internally') {
      setSelectedPlatform([]);
      setSelectedStatus([]);
      setSelectedStage([]);
      setSelectedJobOpening([]);
      setSelectedRequisition([]);
      setSelectedDate(null);
      setShowSelectedDate(null);
    }
  }, [location])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex align-center">
            <ul className="nav nav-tabs " id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="advance-filter-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#advancefilter"
                  type="button"
                  role="tab"
                  aria-controls="advancefilter"
                  aria-selected="true"
                >
                  Advance Filter
                </button>
              </li>
            </ul>
            {isLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nk-block-head-content d-flex">
            {privileges.includes("candidate_resume_create") ? (
              <div className="nk-block-head-content">
                <NavLink
                  to={"/createcandidateresume"}
                  className="toggle btn btn-icon btn-primary d-md-none"
                  id="button-button-candidateresume"
                >
                  <em className="icon ni ni-plus"></em>
                </NavLink>

                {/* <NavLink
                  to={"/createcandidateresume"}
                  className="toggle btn btn-primary d-none d-md-inline-flex"
                  id="button-button-candidateresume"
                >
                  <em className="icon ni ni-plus"></em>
                  <span>Add Resume</span>
                </NavLink> */}
              </div>
            ) : (
              " "
            )}
          </div>
        </div>
      </div>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="advancefilter"
          role="tabpanel"
          aria-labelledby="advance-filter-tab"
        >
          <div className="card">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="row g-4">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div class="nk-block-head-content">
                      <h6 class="nk-block-title">Source</h6>
                    </div>
                    <div class="nk-block-head-content mt-3">
                      <Select
                        options={combine_agency_college_portals}
                        isMulti
                        value={selectedPlatform}
                        onChange={handlePlatformChange}
                        id="select-form-college_portals"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div class="nk-block-head-content">
                      <h6 class="nk-block-title">Stage</h6>
                    </div>
                    <div class="nk-block-head-content mt-3">
                      <Select
                        options={hiringStageDropdownData?.map((info) => ({
                          value: info?.value,
                          label: info?.name,
                        }))}
                        value={selectedStage}
                        isMulti
                        onChange={handleStageChange}
                        id="select-form-status-tags"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div class="nk-block-head-content">
                      <h6 class="nk-block-title">Status Tags</h6>
                    </div>
                    <div class="nk-block-head-content mt-3">
                      <Select
                        options={combine_status}
                        isClearable={true}
                        value={selectedStatus}
                        onChange={handleStatusChange}
                        id="select-form-status-tags"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div class="nk-block-head-content">
                      <h6 class="nk-block-title">Job Opening</h6>
                    </div>
                    <div class="nk-block-head-content mt-3">
                      <Select
                        options={jobOpeningDropdownData?.map(
                          (info) => ({
                            value: info.id,
                            label:
                              info.job_title +
                              " " +
                              info.department_name +
                              " " +
                              info.team_name,
                          })
                        )}
                        value={selectedJobOpening}
                        isClearable={true}
                        isLoading={isGenricDropdownDataLoading}
                        onChange={handleJobOpeningChange}
                        id="select-form-status-tags"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div class="nk-block-head-content">
                      <h6 class="nk-block-title">Requisition</h6>
                    </div>
                    <div class="nk-block-head-content mt-3">
                      <Select
                        options={rawMapRequisition.map((pro) => ({
                          value: pro.id,
                          label:
                            requisitionID &&
                            requisitionID[0]?.name +
                            "" +
                            pro?.id +
                            " " +
                            pro.job_opening?.job_title,
                        }))}
                        value={selectedRequisition}
                        isClearable={true}
                        isLoading={isMapRequisitionLoading}
                        onChange={handleRequisitionChange}
                        id="select-form-status-tags"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div class="nk-block-head-content">
                      <h6 class="nk-block-title">Date</h6>
                    </div>
                    <div class="nk-block-head-content mt-3">
                      <input
                        type="date"
                        className="form-control"
                        value={showSelectedDate || ''}
                        onChange={(event) => handleDate(event)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-control-wrap mt-2">
                  <form>
                    <div className="input-group" style={{ zIndex: "0" }}>
                      <input
                        type="text"
                        value={searchRequest}
                        className="form-control"
                        placeholder="Search by candidate name, email, mobile (e.g. 9xxxxxxxxx; do not include +91) and applied for"
                        onChange={onChangeHandler}
                        id="text-form-input-search"
                      />
                      <div className="input-group-append">
                        <button
                          type="submit"
                          className="btn btn-outline-primary btn-dim"
                          id="button-button-search"
                          onClick={(e) => onSearchHandler("search", e)}
                        >
                          Search
                        </button>
                        <button
                          className="btn btn-outline-success btn-dim"
                          id="button-button-view"
                          onClick={(e) => onSearchHandler("view", e)}
                        >
                          Clear Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {privileges.includes("map_to_requisition")
                ? isCheckboxClicked &&
                (["unmapped"].includes(selectedStatus?.value)) && (
                  <div className="card-inner border-bottom-0">
                    <div className="row">
                      <div className="col-lg-10">
                        <Select
                          options={rawMapRequisition.map((pro) => ({
                            value: pro.id,
                            label:
                              requisitionID &&
                              requisitionID[0]?.name +
                              "" +
                              pro?.id +
                              " " +
                              pro.job_opening?.job_title,
                          }))}
                          isLoading={isMapRequisitionLoading}
                          isMulti
                          onChange={handleRequsiition}
                          id="select-form-allrequisition"
                        />
                      </div>
                      <div className="col-lg-2">
                        {submitLoading ? (
                          <button
                            id="button-loading"
                            className="btn text-dark"
                            disabled
                          >
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-md btn-primary"
                            id="submit-button-map_requisition"
                            onClick={mapRequisitionToResume}
                          >
                            Map to Requisition
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )
                : ""}
              <div className="card-inner p-0">
                <div className="table-responsive">
                  <table className="table">
                    <div className="nk-tb-list">
                      <div className="nk-tb-item nk-tb-head">
                        {
                          (["unmapped"].includes(selectedStatus?.value)) && (
                            <div className="nk-tb-col nk-tb-col-check">
                              <div className="custom-control custom-control-sm custom-checkbox notext">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={handleHeaderCheckboxChange}
                                  id="checkbox-form-candidate"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="checkbox-form-candidate"
                                ></label>
                              </div>
                            </div>
                          )}
                        <div className="nk-tb-col">
                          <span>Candidate Name</span>
                        </div>
                        <div className="nk-tb-col">
                          <span>Email-ID</span>
                        </div>
                        <div className="nk-tb-col">
                          <span>Mobile</span>
                        </div>

                        <div className="nk-tb-col">
                          <span>Resume</span>
                        </div>

                        <div className="nk-tb-col">
                          <span>Applied for</span>
                        </div>
                      </div>

                      {shortlistedResumeData.length > 0 ? (
                        shortlistedResumeData.map((post, index) => (
                          <div className="nk-tb-item">
                            {
                              (["unmapped"].includes(selectedStatus?.value)) && (
                                <div className="nk-tb-col nk-tb-col-check">
                                  <div className="custom-control custom-control-sm custom-checkbox notext">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      key={post.id}
                                      id={post.id}
                                      onChange={(event) =>
                                        handleClick(event, post.id)
                                      }
                                      checked={isSelect.includes(post.id)}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for={post.id}
                                    ></label>
                                  </div>
                                </div>
                              )}
                            <div className="nk-tb-col">
                              <span className="tb-product">
                                <span className="title">
                                  {formatName(post.candidate_name)}
                                </span>
                              </span>
                            </div>
                            <div className="nk-tb-col">
                              <span className="tb-sub">
                                {post.email || "-"}
                              </span>
                            </div>
                            <div className="nk-tb-col">
                              <span className="tb-sub">
                                {post.mobile || "-"}
                              </span>
                            </div>

                            <div className="nk-tb-col">
                              <a
                                id="button-resume"
                                href={post.resume}
                                className="btn btn-primary"
                                target="_blank"
                              >
                                <em
                                  className="icon ni ni-file-docs"
                                  style={{ color: "white" }}
                                ></em>
                              </a>
                            </div>

                            <div className="nk-tb-col">
                              {post?.details?.length !== 0 ? (
                                [...new Set(post?.details?.map(info => info?.job?.job_title))]
                                  .map((jobTitle, index) =>
                                    jobTitle ? (
                                      <span key={index} className="tb-lead">
                                        <span className="title">{jobTitle}</span>
                                      </span>
                                    ) : null
                                  )
                              ) : (
                                <span className="tb-lead">
                                  <span className="title">-</span>
                                </span>
                              )}
                            </div>
                          </div>
                        ))
                      ) : isData ? (
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                Good job! You are all caught up.
                              </span>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="nk-tb-item">
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">Loading...</span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </table>
                </div>
              </div>

              <div className="card-inner">
                <div className="nk-block-between-md g-3">
                  <div className="g">
                    <ReactPaginate
                      key={resumePageCount}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={resumePageCount}
                      marginPagesDisplayed={2}
                      onPageChange={(data) =>
                        handleResumeClick(data, searchRequest)
                      }
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                  <div className="g">
                    <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                      <div>
                        Total entries:
                        <span className="fw-bold">
                          {resumeCount === null ? 0 : resumeCount}
                        </span>
                      </div>
                      <div>No of entries per page:</div>
                      <div>
                        <select
                          className="form-select js-select2"
                          data-search="on"
                          data-dropdown="xs center"
                          value={resumeEntries}
                          onChange={handleEntries}
                          id="select-form-enteries"
                        >
                          <option value="5">5</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="tab-pane fade" id="singlefilter"
          role="tabpanel" aria-labelledby="single-filter-tab">
          Single Filter Tab
        </div> */}
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ResumePoolAdvanceFilter;
