import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { ScheduleInterviewContext } from "../../Context/ScheduleInterviewContext";
import Swal from "sweetalert2";
import { DatePicker } from "rsuite";
import { format } from "date-fns";

const ScheduleInterviewUpdate = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  //DROPDOWN DATA
  const {
    isDropdownDataLoading,
    interviewTypeDropdownData,
    interviewModeDropdownData,
    HR_TechnicalInterviewerDropdownFilterBy,
    HRTelephonicInterviewerDropdownFilterBy
  } = useContext(TypemasterDropdownContext);
  console.log("interviewTypeDropdownData: ", interviewTypeDropdownData);
  console.log("interviewModeDropdownData: ", interviewModeDropdownData);

  const { getInterviewDetails } = useContext(ScheduleInterviewContext);

  const privileges = secureLocalStorage.getItem("privileges");

  const { isGenricParamsDropdownDataLoading, usersDropdownData } = useContext(
    GenericDropdownContext
  );
  const SMEUsersData = usersDropdownData?.filter((info) => HR_TechnicalInterviewerDropdownFilterBy?.some((rl) => info.role?.find((role) => rl.value === role.role_value)));

  const RCUsersData = usersDropdownData?.filter((info) => HRTelephonicInterviewerDropdownFilterBy?.some((rl) => info.role?.find((role) => rl.value === role.role_value)));
  console.log("RCUsersData: ", RCUsersData);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const [defaultRC, setDefaultRC] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.state?.CandidateData);
  console.log(location.state?.requisition_id);

  const candidate_job_id = location.state?.CandidateData?.id;
  console.log(candidate_job_id);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const [interviewerName, setInterviewerName] = useState(null);
  console.log(interviewerName);
  const [availableSlots, setAvailableSlots] = useState([]);
  console.log(availableSlots);
  const [isAvailableSlotsLoading, setIsAvailableSlotsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const formatName = (name) => {
    if (!name) {
      return "-";
    } else {
      const parts = name.split(" ");
      console.log(parts);
      const firstName = parts[0]
        ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
        : "";
      const lastName = parts[1]
        ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
        : "";
      return `${firstName} ${lastName}`;
    }
  };

  const [isDate_Slot_Avalaible, setIsDate_Slot_Avalaible] = useState(false)
  console.log(isDate_Slot_Avalaible)

  const handleSlotsCheck = () => {
    if (location.state.editpost.interviewer_name?.length === interviewerName?.length) {
      setIsDate_Slot_Avalaible(location.state.editpost.interviewer_name.every((val) => interviewerName?.some((info) => info.value === val.id)))
      console.log("if")
    } else {
      console.log("false")
      setIsDate_Slot_Avalaible(false);
    }
  }

  const onSubmit = (data) => {

    console.log(data);
    let start_date;
    // if (data.slot_avaliable) {
    //   start_date = moment(data.slot_avaliable)
    //     .utcOffset("+05:30")
    //     .format()
    //     .split("+");
    // } else {
    //   start_date = moment(location?.state?.editpost?.interview_datetime)
    //     .utcOffset("+05:30")
    //     .format()
    //     .split("+");
    // }

    if (location?.state?.editpost?.interview_type === "HR Telephonic") {
      if (data.date_time) {
        start_date = moment(data.date_time).format("YYYY-MM-DDTHH:mm:ss");
      } else {
        start_date = "Invalid date";
      }
    } else {
      if (data?.slot_avaliable) {
        start_date = moment(data.slot_avaliable)
          .format("YYYY-MM-DDTHH:mm:ss");
      } else {
        if (isDate_Slot_Avalaible) {
          start_date = location?.state?.editpost?.interview_datetime ? moment(location?.state?.editpost?.interview_datetime)
            .format("YYYY-MM-DDTHH:mm:ss") : null;
        } else {
          start_date = null;
        }
      }
    }

    var user = JSON.stringify({
      candidate_job_id:
        location?.state?.editpost?.candidate_job?.candidate_resume?.id,
      requisition_id: location?.state?.editpost?.requisition?.id,
      interview_mode: data.interview_mode.value,
      interviewer_name_id: data.interview_name.map((val) => val.value),
      interview_type: location?.state?.editpost?.interview_type,
      // interview_datetime: start_date[0]
      //   ? format(new Date(start_date[0]), "yyyy-MM-dd'T'HH:mm:ss")
      //   : null,
      interview_datetime: start_date,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api/candidateinterview/${location?.state?.editpost?.id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };
    console.log(config);

    console.log(user);
    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully Updated the scheduled interview",
        });
        // getInterviewDetails();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        reset();
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to update schedule interview",
        });
      });
  };

  console.log(location?.state?.editpost);

  const getAvaliableSlots = async () => {
    const interview_name = interviewerName?.map((api) => api.value);
    const access = secureLocalStorage.getItem("access");
    const url =
      VARIABLES.url + `/api/available-slots/?interviewer_id=${interview_name}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    setIsAvailableSlotsLoading(true);
    try {
      const res = await axios(config);
      console.log(res.data.data);
      let response = res.data.data;
      if (response.length !== 0) {
        setAvailableSlots(res.data.data);
        setIsAvailableSlotsLoading(false);
      } else {
        setAvailableSlots(res.data.data);
        setIsAvailableSlotsLoading(true);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsAvailableSlotsLoading(true);
      } else {
        setIsAvailableSlotsLoading(false);
      }
    }
  };

  const handleDelete = (id) => {
    const access = secureLocalStorage.getItem("access");
    const url =
      VARIABLES.url + `/api/candidateinterview/${id}/?updated_by_id=${userID}`;
    console.log(url);

    var config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    axios(config)
      .then((response) => {
        getInterviewDetails();
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully deleted the requisition",
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
        } else {
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to delete the requisition",
          });
        }
      });
  };

  const handleDeleteAlert = () => {
    Swal.fire({
      text: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(location?.state?.editpost?.id);
      }
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  useEffect(() => {
    getAvaliableSlots();
    handleSlotsCheck();
  }, [interviewerName, location]);

  useEffect(() => {
    var defaultValues = {};
    const rcusers = usersDropdownData?.filter((info) =>
      info.role?.find((role) => role.role_name?.includes("RC"))
    );

    const value = rcusers?.map((user) => {
      return {
        value: user?.user?.id,
        label: user?.user?.first_name + " " + user?.user?.last_name,
      };
    });
    console.log(value);
    defaultValues.interview_name = value;
    reset(defaultValues);
  }, [usersDropdownData]);

  console.log(defaultRC);

  useEffect(() => {
    console.log(location.state.editpost);
    const editedSchedule = location.state.editpost;
    console.log(editedSchedule);
    if (editedSchedule) {
      var defaultValues = {};
      if (editedSchedule?.interview_mode) {
        const getInterviewMode = {
          value: editedSchedule.interview_mode,
          label: editedSchedule.interview_mode,
        };
        defaultValues.interview_mode = getInterviewMode;
      }
      if (editedSchedule?.interviewer_name) {
        const getInterviewerName = editedSchedule.interviewer_name.map(
          (api) => {
            return {
              value: api.id,
              label: api.first_name + " " + api.last_name,
            };
          }
        );
        defaultValues.interview_name = getInterviewerName;
        setInterviewerName(getInterviewerName);
      }
      reset(defaultValues);
    }
  }, []);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              Schedule candidate Interview
            </h3>
          </div>
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card">
          <div className="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-12 col-md-12 col-sm-12 justify-content-between d-flex">
                  <label className="form-label fs-4">
                    Candidate name : &nbsp;
                    {formatName(
                      location?.state?.editpost?.candidate_job?.candidate_resume
                        ?.candidate_name
                    )}
                  </label>

                  {privileges.includes("schedule_interview_delete") ? (
                    <>
                      <a
                        className="toggle btn btn-sm btn-icon btn-primary d-md-none"
                        id="button-button-smescheduledinterviews"
                        onClick={handleDeleteAlert}
                      >
                        <em className="icon ni ni-trash"></em>
                      </a>
                      <a
                        className="toggle btn btn-sm btn-primary d-none d-md-inline-flex"
                        id="button-button-smescheduledinterviews"
                        onClick={handleDeleteAlert}
                      >
                        <em className="icon ni ni-trash"></em>
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
                      <label className="form-label"> Interview Type :</label>
                      <div className="fw-bold ms-3">
                        {location?.state?.editpost?.interview_type}
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
                      <label className="form-label">
                        Interview Selected Date-Time :
                      </label>
                      <div className="fw-bold ms-3">
                        {location?.state?.editpost?.interview_datetime ? moment(
                          location?.state?.editpost?.interview_datetime
                        ).format("MMM DD, YYYY h:mm a") : <span className="text-danger">Not selected</span>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12"></div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interview Mode
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={interviewModeDropdownData?.map((info) => ({
                          value: info.value,
                          label: info.name,
                        }))}
                        isClearable={true}
                        isLoading={isDropdownDataLoading}
                        id="select-forms-interviewmode"
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="interview_mode"
                    control={control}
                  />
                  {errors.interview_mode && (
                    <span className="fs-8 text-danger">
                      Interview Mode is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interviewer Name<span className="text-danger"> *</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={(location?.state?.editpost?.interview_type ===
                          "HR Telephonic"
                          ? RCUsersData
                          : SMEUsersData
                        )?.map((info) => ({
                          value: info.user.id,
                          label: `${info.user.first_name} ${info.user.last_name}`,
                        }))}
                        isClearable={true}
                        isLoading={isGenricParamsDropdownDataLoading}
                        isMulti
                        onChange={(e) => {
                          if (e) {
                            setInterviewerName(e);
                          } else {
                            setInterviewerName(null);
                          }
                          field.onChange(e);
                        }}
                        id="select-forms-interviewname"
                      />
                    )}
                    rules={{ required: true }}
                    name="interview_name"
                    control={control}
                  />
                  {errors.interview_name && (
                    <span className="fs-8 text-danger">
                      Interview Name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Interview Date-Time {location?.state?.editpost?.interview_type === "HR Telephonic" && (<span className="text-danger"> *</span>)}
                  </label>
                  {location?.state?.editpost?.interview_type ===
                    "HR Telephonic" ? (
                    <div className="col-lg-6 w-100">
                      <div className="form-group">
                        <Controller
                          name="date_time"
                          control={control}
                          defaultValue={
                            location?.state?.editpost?.interview_datetime
                              ? new Date(location?.state?.editpost?.interview_datetime)
                              : null
                          }
                          rules={{ required: "Date-Time is required" }}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              format="yyyy-MM-dd'T'HH:mm:ss"
                              style={{ width: "500px", minWidth: "100%" }}
                              value={field.value || null} // This ensures the DatePicker displays the correct value
                              onChange={(date) => field.onChange(date)} // Handles the value update correctly
                              shouldDisableDate={(date) => {
                                const today = new Date();
                                return date < today.setHours(0, 0, 0, 0);
                              }}
                              ref={(ref) => {
                                field.ref({
                                  focus: () => {
                                  },
                                });
                              }}
                            // shouldDisableHour={(date) => {
                            //   const today = new Date();
                            //   return date && date.toDateString() === today.toDateString() ? today.getHours() : [];
                            // }}
                            />
                          )}
                        />
                        {errors.date_time && (
                          <span className="text-danger">{errors.date_time.message}</span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <ul className="row g-3">
                      {interviewerName && availableSlots.length === 0 ? (
                        <div>No Slots Available</div>
                      ) : availableSlots.length === 0 ? (
                        <div>Select Interviewer Name First</div>
                      ) : (
                        <div></div>
                      )}
                      {availableSlots.map((slot, index) => {
                        return (
                          <li
                            className="col-sm-12 col-lg-4 col-md-4"
                            key={index}
                          >
                            <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full checked">
                              <input
                                type="radio"
                                id={`checkbox-form-slot_avaliable-${index}`}
                                className="custom-control-input"
                                name="slot_avaliable"
                                value={slot.slot_start_time}
                                {...register("slot_avaliable")}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`checkbox-form-slot_avaliable-${index}`}
                              >
                                <span className="d-flex flex-column text-center">
                                  <span className="lead-text mb-1 mt-3">
                                    {moment(slot.slot_start_time).format(
                                      "MMM DD, YYYY h:mm a"
                                    )}
                                  </span>
                                  {/* <span className="sub-text">{moment(slot.slot_end_time).format('MMM DD, YYYY h:mm a')}</span> */}
                                </span>
                              </label>
                            </div>
                          </li>
                        );
                      })}
                      {errors.slot_avaliable && (
                        <span className="text-danger">{errors.slot_avaliable.message}</span>
                      )}
                    </ul>
                  )}
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-secondary me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createscheduleinterview"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScheduleInterviewUpdate;
