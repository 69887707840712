import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CandidateDetailsContext } from "../../Context/CandidateDetailsContext";
import moment from "moment";

const CandidateStatus = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const candidate_id = location.state?.candidate_id;
  const req_id = location.state?.requisition_id;

  const {
    candidateAdditionalData,
    getCandidateAdditionalData,
  } = useContext(CandidateDetailsContext);

  const formatName = (name) => {
    if (!name) {
      return "-";
    } else {
      const parts = name.split(" ");
      console.log(parts);
      const firstName = parts[0]
        ? parts[0].charAt(0).toUpperCase() + parts[0].slice(1).toLowerCase()
        : "";
      const lastName = parts[1]
        ? parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase()
        : "";
      return `${firstName} ${lastName}`;
    }
  };

  useEffect(() => {
    getCandidateAdditionalData(candidate_id, req_id);
  }, []);

  return (
    <>
      {location?.state?.status && (
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-end">
            <div class="nk-block-head-content">
              <a
                id="back-button"
                className="btn btn-light d-none d-sm-inline-flex"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </a>
              <a
                id="back-button"
                className="btn btn-icon btn-light d-inline-flex d-sm-none"
                onClick={() => navigate(-1)}
              >
                <em class="icon ni ni-arrow-left"></em>
              </a>
            </div>
          </div>
        </div>
      )}

      <div className="nk-block">
        <div className="card mb-3">
          <div className="card-inner-group">
            {location?.state?.status && (
              <div className="card-inner border-bottom-0">
                <h6 className="nk-block-title">
                  Candidate Name :{" "}
                  {formatName(location.state.editpost?.candidate_name)}
                </h6>
              </div>
            )}
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Tab Name</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Details</span>
                      </div>
                    </div>

                    <div className="nk-tb-item">
                      <div className="nk-tb-col">
                        <span className="tb-product">
                          <span className="title">Telephonic Details</span>
                        </span>
                      </div>

                      <div className="nk-tb-col">
                        <div class="row gy-3">
                          <div class="col-lg-4">
                            <span class="sub-text">Candidate Status</span>

                            {candidateAdditionalData?.hr_telephonic_data?.candidate_accept_reject !== undefined ? (
                              candidateAdditionalData.hr_telephonic_data.candidate_accept_reject === 0 ? (
                                <span className="badge bg-warning">Draft</span>
                              ) : candidateAdditionalData.hr_telephonic_data.candidate_accept_reject === 1 ? (
                                <span className="badge bg-success">
                                  Accepted
                                </span>
                              ) : candidateAdditionalData.hr_telephonic_data.candidate_accept_reject === 2 ? (
                                <span className="badge bg-danger">
                                  Rejected
                                </span>
                              ) : null
                            ) : (
                              "-"
                            )}
                          </div>

                          <div class="col-lg-4">
                            <span class="sub-text">Interview Date-Time</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.hr_telephonic_data
                                ?.interview_datetime
                                ? moment(candidateAdditionalData?.hr_telephonic_data
                                  ?.interview_datetime).format("MMM DD, YYYY h:mm a")
                                : "-"}
                            </span>
                          </div>

                          <div class="col-lg-4">
                            <span class="sub-text">
                              Interview Mode and Type
                            </span>
                            <span class="caption-text">
                              {candidateAdditionalData?.hr_telephonic_data
                                ?.interview_mode &&
                                candidateAdditionalData?.hr_telephonic_data
                                  ?.interview_type
                                ? candidateAdditionalData?.hr_telephonic_data
                                  ?.interview_mode +
                                " - " +
                                candidateAdditionalData?.hr_telephonic_data
                                  ?.interview_type
                                : "-"}
                            </span>
                          </div>
                          <div class="col-lg-4">
                            <span class="sub-text">Interviewer Name</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.hr_telephonic_data?.interviewer_name.map(
                                (api) =>
                                  `${api?.first_name || "-"} ${api?.last_name || "-"
                                  }`
                              ) || "-"}
                            </span>
                          </div>
                          <div class="col-lg-4">
                            <span class="sub-text">Total Score</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.hr_telephonic_data?.total_score !== undefined && candidateAdditionalData?.hr_telephonic_data?.total_score !== null
                                ? candidateAdditionalData?.hr_telephonic_data?.total_score
                                : "-"}
                            </span>
                          </div>
                          <div class="col-lg-4">
                            <span class="sub-text">Comments</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.hr_telephonic_data
                                ?.comments || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="nk-tb-item">
                      <div className="nk-tb-col">
                        <span className="tb-product">
                          <span className="title">Technical Details</span>
                        </span>
                      </div>

                      {candidateAdditionalData?.technical_data?.length !== 0 ? (
                        <div className="nk-tb-col">
                          {candidateAdditionalData?.technical_data?.map((info, index) => (
                            <>
                              <div class="row gy-3 mt-3">
                                {candidateAdditionalData?.technical_data?.length > 1 && (
                                  <span className="fw-bold fs-6">{info?.interview_type} :-</span>
                                )}
                                <div class="col-lg-4">
                                  <span class="sub-text">Candidate Status</span>
                                  {info
                                    ?.candidate_accept_reject ? (
                                    info
                                      ?.candidate_accept_reject === 0 ? (
                                      <span class="badge bg-warning">Draft</span>
                                    ) : info
                                      ?.candidate_accept_reject === 1 ? (
                                      <span class="badge bg-success">Accepted</span>
                                    ) : info
                                      ?.candidate_accept_reject === 2 ? (
                                      <span class="badge bg-danger">Rejected</span>
                                    ) : null
                                  ) : (
                                    "-"
                                  )}
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">Interview Date-Time</span>
                                  <span class="caption-text">
                                    {info
                                      ?.interview_datetime
                                      ? moment(info
                                        ?.interview_datetime).format("MMM DD, YYYY h:mm a")
                                      : "-"}
                                  </span>
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">
                                    Interview Mode and Type
                                  </span>
                                  <span class="caption-text">
                                    {info
                                      ?.interview_mode &&
                                      info?.interview_type
                                      ? info
                                        ?.interview_mode +
                                      " - " +
                                      info
                                        ?.interview_type
                                      : "-"}
                                  </span>
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">Interviewer Name</span>
                                  <span class="caption-text">
                                    {info?.interviewer_name?.map(
                                      (api) =>
                                        `${api?.first_name || "-"} ${api?.last_name || "-"
                                        }`
                                    ) || "-"}
                                  </span>
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">Total Score</span>
                                  <span class="caption-text">
                                    {info?.total_score !== undefined && info?.total_score !== null
                                      ? info?.total_score
                                      : "-"}
                                  </span>
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">Comments</span>
                                  <span class="caption-text">
                                    {info
                                      ?.comments || "-"}
                                  </span>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      ) :
                        " "
                      }
                    </div>
                      
                    <div className="nk-tb-item">
                      <div className="nk-tb-col">
                        <span className="tb-product">
                          <span className="title">HR Interview Details</span>
                        </span>
                      </div>

                      {candidateAdditionalData?.hr_data?.length !== 0 ? (
                        <div className="nk-tb-col">
                          {candidateAdditionalData?.hr_data?.map((info, index) => (
                            <>
                              <div class="row gy-3 mt-3">
                                {candidateAdditionalData?.hr_data?.length > 1 && (
                                  <span className="fw-bold fs-6">{info?.interview_type} :-</span>
                                )}
                                <div class="col-lg-4">
                                  <span class="sub-text">Candidate Status</span>
                                  {info
                                    ?.candidate_accept_reject ? (
                                    info
                                      ?.candidate_accept_reject === 0 ? (
                                      <span class="badge bg-warning">Draft</span>
                                    ) : info
                                      ?.candidate_accept_reject === 1 ? (
                                      <span class="badge bg-success">Accepted</span>
                                    ) : info
                                      ?.candidate_accept_reject === 2 ? (
                                      <span class="badge bg-danger">Rejected</span>
                                    ) : null
                                  ) : (
                                    "-"
                                  )}
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">Interview Date-Time</span>
                                  <span class="caption-text">
                                    {info
                                      ?.interview_datetime
                                      ? moment(info
                                        ?.interview_datetime).format("MMM DD, YYYY h:mm a")
                                      : "-"}
                                  </span>
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">
                                    Interview Mode and Type
                                  </span>
                                  <span class="caption-text">
                                    {info
                                      ?.interview_mode &&
                                      info?.interview_type
                                      ? info
                                        ?.interview_mode +
                                      " - " +
                                      info
                                        ?.interview_type
                                      : "-"}
                                  </span>
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">Interviewer Name</span>
                                  <span class="caption-text">
                                    {info?.interviewer_name?.map(
                                      (api) =>
                                        `${api?.first_name || "-"} ${api?.last_name || "-"
                                        }`
                                    ) || "-"}
                                  </span>
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">Total Score</span>
                                  <span class="caption-text">
                                    {info?.total_score !== undefined && info?.total_score !== null
                                      ? info?.total_score
                                      : "-"}
                                  </span>
                                </div>
                                <div class="col-lg-4">
                                  <span class="sub-text">Comments</span>
                                  <span class="caption-text">
                                    {info
                                      ?.comments || "-"}
                                  </span>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      ) :
                        " "
                      }
                    </div>
                   
                    <div className="nk-tb-item">
                      <div className="nk-tb-col">
                        <span className="tb-product">
                          <span className="title">Smart Hire Details</span>
                        </span>
                      </div>

                      <div className="nk-tb-col">
                        <div class="row gy-3">
                          <div class="col-lg-4">
                            <span class="sub-text">Candidate Status</span>

                            {candidateAdditionalData?.shf_data
                              ?.candidate_accept_reject ? (
                              candidateAdditionalData?.shf_data
                                ?.candidate_accept_reject === 0 ? (
                                <span class="badge bg-warning">Draft</span>
                              ) : candidateAdditionalData?.shf_data
                                ?.candidate_accept_reject === 1 ? (
                                <span class="badge bg-success">Accepted</span>
                              ) : candidateAdditionalData?.shf_data
                                ?.candidate_accept_reject === 2 ? (
                                <span class="badge bg-danger">Rejected</span>
                              ) : null
                            ) : (
                              "-"
                            )}
                          </div>

                          <div class="col-lg-4">
                            <span class="sub-text">Total Score</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.shf_data?.total_score !== undefined && candidateAdditionalData?.shf?.total_score !== null
                                ? candidateAdditionalData?.shf_data?.total_score
                                : "-"}
                            </span>
                          </div>
                          <div class="col-lg-4">
                            <span class="sub-text">Comments</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.shf_data
                                ?.comments || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="nk-tb-item">
                      <div className="nk-tb-col">
                        <span className="tb-product">
                          <span className="title">Assignment Details</span>
                        </span>
                      </div>

                      <div className="nk-tb-col">
                        <div class="row gy-3">
                          <div class="col-lg-4">
                            <span class="sub-text">Candidate Status</span>
                            {candidateAdditionalData?.assignment_data
                              ?.selection_status ? (
                              candidateAdditionalData?.assignment_data
                                ?.selection_status === 0 ? (
                                <span class="badge bg-warning">Draft</span>
                              ) : candidateAdditionalData?.assignment_data
                                ?.selection_status === 1 ? (
                                <span class="badge bg-success">Accepted</span>
                              ) : candidateAdditionalData?.assignment_data
                                ?.selection_status === 2 ? (
                                <span class="badge bg-danger">Rejected</span>
                              ) : null
                            ) : (
                              "-"
                            )}
                          </div>
                          <div class="col-lg-4">
                            {candidateAdditionalData?.assignment_data?.choose_assignment?.assignment_document &&
                              candidateAdditionalData?.assignment_data?.choose_assignment?.assignment_title ? (
                              <>
                                <span class="sub-text">Assignment name - {candidateAdditionalData?.assignment_data?.choose_assignment?.assignment_title}
                                </span>
                                <a
                                  target="_blank"
                                  href={
                                    candidateAdditionalData?.assignment_data
                                      ?.choose_assignment?.assignment_document
                                  }
                                  class="caption-text text-break"
                                >
                                  View assigned assignment
                                </a>
                              </>
                            ) : (
                              "-"
                            )}
                          </div>
                          <div class="col-lg-4">
                            <span class="sub-text">Start Date</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.assignment_data?.start_date
                                ? candidateAdditionalData?.assignment_data?.start_date
                                : "-"}
                            </span>
                          </div>
                          <div class="col-lg-4">
                            <span class="sub-text">End Date</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.assignment_data?.end_date
                                ? candidateAdditionalData?.assignment_data?.end_date
                                : "-"}
                            </span>
                          </div>
                          {candidateAdditionalData?.assignment_data?.extended_end_date !== null &&
                            <div class="col-lg-4">
                              <span class="sub-text">Extended End Date</span>
                              <span class="caption-text">
                                {candidateAdditionalData?.assignment_data?.end_date
                                  ? candidateAdditionalData?.assignment_data?.end_date
                                  : "-"}
                              </span>
                            </div>
                          }
                          <div class="col-lg-4">
                            <span class="sub-text">Assignment Review By</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.assignment_data?.assignment_conducted_user?.map(
                                (api, index) => `${api.first_name} ${api.last_name}${index === candidateAdditionalData?.assignment_data?.assignment_conducted_user?.length - 1 ? '.' : ', '}`
                              ) || "-"}
                            </span>
                          </div>

                          <div class="col-lg-4">
                            <span class="sub-text">Assignment submitted by candidate
                            </span>
                            {candidateAdditionalData?.assignment_data?.upload_assignment ? (
                              <a
                                target="_blank"
                                href={
                                  candidateAdditionalData?.assignment_data?.upload_assignment
                                }
                                class="caption-text text-break"
                              >
                                View submitted assignment
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>

                          <div class="col-lg-4">
                            <span class="sub-text">Total Score</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.assignment_data?.total_score !== undefined && candidateAdditionalData?.assignment_data?.total_score !== null
                                ? candidateAdditionalData?.assignment_data?.total_score
                                : "-"}
                            </span>
                          </div>
                          <div class="col-lg-4">
                            <span class="sub-text">Comments</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.assignment_data
                                ?.comments || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="nk-tb-item">
                      <div className="nk-tb-col">
                        <span className="tb-product">
                          <span className="title">Psychometry Test Details</span>
                        </span>
                      </div>

                      <div className="nk-tb-col">
                        <div class="row gy-3">
                          <div class="col-lg-4">
                            <span class="sub-text">Candidate Status</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.exam_data
                                ?.selection_status ? (
                                candidateAdditionalData?.exam_data
                                  ?.selection_status === 0 ? (
                                  <span class="badge bg-warning">Draft</span>
                                ) : candidateAdditionalData?.exam_data
                                  ?.selection_status === 1 ? (
                                  <span class="badge bg-success">Accepted</span>
                                ) : candidateAdditionalData?.exam_data
                                  ?.selection_status === 2 ? (
                                  <span class="badge bg-danger">Rejected</span>
                                ) : null
                              ) : (
                                "-"
                              )}
                            </span>
                          </div>

                          <div class="col-lg-4">
                            {candidateAdditionalData?.exam_data?.test_name &&
                              candidateAdditionalData?.exam_data?.test_link ? (
                              <>
                                <span class="sub-text">Test name - {candidateAdditionalData?.exam_data?.test_name}
                                </span>
                                <a
                                  target="_blank"
                                  href={
                                    candidateAdditionalData?.exam_data
                                      ?.test_link
                                  }
                                  class="caption-text text-break"
                                >
                                  Visit exam link
                                </a>
                              </>
                            ) : (
                              "-"
                            )}
                          </div>
                          <div class="col-lg-4">
                            <span class="sub-text">Test Conducted</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.exam_data
                                ?.test_conducted_user?.first_name ||
                                candidateAdditionalData?.exam_data
                                  ?.test_conducted_user?.last_name
                                ? candidateAdditionalData?.exam_data
                                  ?.test_conducted_user?.first_name +
                                " " +
                                candidateAdditionalData?.exam_data
                                  ?.test_conducted_user?.last_name
                                : "-"}
                            </span>
                          </div>
                       
                          <div class="col-lg-4">
                            <span class="sub-text">Comments</span>
                            <span class="caption-text">
                              {candidateAdditionalData?.exam_data?.comments ||
                                "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="nk-tb-item">
                      <div className="nk-tb-col">
                        <span className="tb-product">
                          <span className="title">Know Your Candidate</span>
                        </span>
                      </div>

                      <div className="nk-tb-col">
                        <div class="row gy-3">
                          {candidateAdditionalData?.kyc_data &&
                            candidateAdditionalData?.kyc_data?.documents?.length >
                            0 ? (
                            candidateAdditionalData?.kyc_data?.documents.map(
                              (api) => (
                                <div class="col-lg-4">
                                  <span class="sub-text">
                                    {api?.document_name}
                                  </span>
                                  <a
                                    target="_blank"
                                    href={api?.document}
                                    class="caption-text text-break"
                                  >
                                    View Document
                                  </a>
                                </div>
                              )
                            )
                          ) : (
                            <div class="col-lg-4">
                              <span class="sub-text">
                                No KYC Details Updated
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {props?.from === "MD" &&
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">HRD Approval</span>
                          </span>
                        </div>

                        <div className="nk-tb-col">
                          <div class="row gy-3">
                            <div class="col-lg-4">
                              <span class="sub-text">HRD Approval</span>
                              <span class="caption-text">
                                {candidateAdditionalData?.hrd_approval
                                  ? (
                                    candidateAdditionalData?.hrd_approval
                                      === 0 ? (
                                      <span class="badge bg-warning">Draft</span>
                                    ) : candidateAdditionalData?.hrd_approval
                                      === 1 ? (
                                      <span class="badge bg-success">Accepted</span>
                                    ) : candidateAdditionalData?.hrd_approval
                                      === 2 ? (
                                      <span class="badge bg-danger">Rejected</span>
                                    ) : null
                                  ) : (
                                    "-"
                                  )}
                              </span>
                            </div>

                            <div class="col-lg-4">
                              <span class="sub-text">Score</span>
                              <span class="caption-text">
                                {candidateAdditionalData?.hrd_score ? candidateAdditionalData?.hrd_score : "-"}
                              </span>
                            </div>

                            <div class="col-lg-4">
                              <span class="sub-text">Comments</span>
                              <span class="caption-text">
                                {candidateAdditionalData?.hrd_comments ? candidateAdditionalData?.hrd_comments : "-"}
                              </span>
                            </div>

                            <div class="col-lg-4">
                              <span class="sub-text">Feedback</span>
                              <span class="caption-text">
                                {candidateAdditionalData?.hrd_feedback ? candidateAdditionalData?.hrd_feedback : "-"}
                              </span>
                            </div>

                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateStatus;
