import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { RequestedJobOpeningContext } from "../../Context/RequestedJobOpeningContext";
import ReactPaginate from "react-paginate";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import WelcomeMessage from "../../Base/WelcomeMessage";
import moment from "moment";
import axios from "axios";
import { VARIABLES } from "../../Constants";

const ScheduleCandidateInterview = () => {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const [searchRequest, setSearchRequest] = useState("");
  console.log(searchRequest);
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");

  const {
    rawRCRequisitionData,
    getRCRequisitionData,
    RCRequisitionEntries,
    RCRequisitionpageCount,
    setRCRequisitionEntries,
    RCRequisitionCount,
    handleRCRequisitionPageClick,
    isRCRequisitionData,
    isRCRequisitionLoading,
  } = useContext(RequestedJobOpeningContext);
  console.log(rawRCRequisitionData);

  const { requisitionID } = useContext(TypemasterDropdownContext);

  const handleEntries = (event) => {
    setRCRequisitionEntries(event.target.value);
  };

  const onChangeHandler = (event) => {
    setSearchRequest(event.target.value);
  };

  const onSearchHandler = (method, event) => {
    event.preventDefault();
    if (method === "search") {
      getRCRequisitionData(1, searchRequest);
    } else {
      getRCRequisitionData(1);
      setSearchRequest("");
    }
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      getRCRequisitionData(1, searchRequest, access);
    } else {
      let access = secureLocalStorage.getItem("access");
      getRCRequisitionData(1, searchRequest, access);
    }
  }, [RCRequisitionEntries, isAccess]);

  return (
    <>
      <WelcomeMessage />
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h3 className="nk-block-title page-title">
              Interview Scheduling
            </h3>
            {isRCRequisitionLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="card">
          <div className="card-inner-group">
            <div className="card-inner border-bottom-0">
              <div className="form-control-wrap">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchRequest}
                      className="form-control"
                      placeholder="Search using Requisition ID (e.g., 126), Job Title, Department and Priority"
                      onChange={onChangeHandler}
                      id="text-form-input-search"
                    />
                    <div className="input-group-append">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-dim"
                        id="button-button-search"
                        onClick={(e) => onSearchHandler("search", e)}
                      >
                        Search
                      </button>
                      <button
                        className="btn btn-outline-success btn-dim"
                        id="button-button-view"
                        onClick={(e) => onSearchHandler("view", e)}
                      >
                        Clear Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-inner p-0">
              <div className="table-responsive">
                <table className="table">
                  <div className="nk-tb-list">
                    <div className="nk-tb-item nk-tb-head">
                      <div className="nk-tb-col">
                        <span>Approved on</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Requisition ID</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Job Title</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Department</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Priority</span>
                        <br />
                        <span>(Set by TAM)</span>
                      </div>
                      <div className="nk-tb-col">
                        <span>Pending Since (Days)</span>
                        <br />
                        <span>for shortlisting</span>
                      </div>
                    </div>

                    {rawRCRequisitionData.length !== 0 ? (
                      rawRCRequisitionData.map((post, index) => (
                        <div className="nk-tb-item" key={index}>
                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {post?.approval_on
                                  ? moment(post?.approval_on).format(
                                    "MMM DD, YYYY"
                                  )
                                  : "-"}
                              </span>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-lead">
                              <span className="title">
                                {privileges.includes(
                                  "shortlisted_candidate_details"
                                ) ? (
                                  <NavLink
                                    to="/schedulecandidateview"
                                    id="button-button-schedulecandidateview"
                                    state={{
                                      editpost: post,
                                      from: 'sme_approved'
                                    }}
                                  >
                                    <span>
                                      {(requisitionID &&
                                        requisitionID[0]?.name +
                                        post.requisition.id) ||
                                        "-"}
                                    </span>
                                  </NavLink>
                                ) : (
                                  <>
                                    {" "}
                                    {(requisitionID &&
                                      requisitionID[0]?.name +
                                      post.requisition.id) ||
                                      "-"}
                                  </>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-product">
                              <span className="title">
                                {privileges.includes("job_opening_retrieve") ? (
                                  <NavLink
                                    to={"/rerequisitiondetails"}
                                    state={{ editpost: post }}
                                  >
                                    {post?.job_opening?.job_title || "-"}
                                  </NavLink>
                                ) : (
                                  <> {post?.job_opening?.job_title || "-"}</>
                                )}
                              </span>
                            </span>
                          </div>
                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              <NavLink
                                to={"/departmentrequisitions"}
                                state={{ editpost: post?.job_opening?.department?.id }}
                              >
                                {post?.job_opening?.department
                                  ?.department_display_name || "-"}
                              </NavLink>
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.requisition?.priority || "-"}
                            </span>
                          </div>

                          <div className="nk-tb-col">
                            <span className="tb-sub">
                              {post?.sme_shortlist_pending_since}
                            </span>
                          </div>

                        </div>
                      ))
                    ) : isRCRequisitionData ? (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Good job! You are all caught up.</span>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="nk-tb-item">
                        <div className="nk-tb-col">
                          <span className="tb-product">
                            <span className="title">Loading...</span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </table>
              </div>
            </div>
            <div className="card-inner">
              <div className="nk-block-between-md g-3">
                <div className="g">
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={RCRequisitionpageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(data) =>
                      handleRCRequisitionPageClick(data, searchRequest)
                    }
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
                <div className="g">
                  <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                    <div>
                      Total entries:
                      <span className="fw-bold">
                        {RCRequisitionCount === null ? 0 : RCRequisitionCount}
                      </span>
                    </div>
                    <div>No of entries per page:</div>
                    <div>
                      <select
                        className="form-select js-select2"
                        data-search="on"
                        data-dropdown="xs center"
                        value={RCRequisitionEntries}
                        onChange={handleEntries}
                        id="select-form-enteries"
                      >
                        <option value="5">5</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleCandidateInterview;
