import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div class="nk-block nk-block-middle wide-md mx-auto">
        <div class="nk-block-content nk-error-ld text-center">
          <img
            class="nk-error-gfx"
            src={require("../../../Resources/images/fsc_animated_logo_black.gif")}
            style={{ maxWidth: "10rem", padding: "0px" }}
          />
          <div class="wide-xs mx-auto">
            <h3 class="nk-error-title">Page Not Found</h3>
            <p class="nk-error-text">
              We are very sorry for inconvenience. It looks like some how you
              entered wrong url.
            </p>
            <p class="nk-error-text">Thankyou for your understanding</p>
            <a class="btn btn-lg btn-primary mt-2" onClick={() => navigate(-1)}>Back To Home</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
