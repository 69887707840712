import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { MasterTableContext } from "../../Context/MasterTableContext";
import { GenericDropdownContext } from "../../Context/GenericDropdownContext";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";
import { TypemasterDropdownContext } from "../../Context/TypemasterDropdownContext";

const CreateDepartment = () => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const userID = secureLocalStorage.getItem("userID");
  const { getDepartmentData } = useContext(MasterTableContext);
  const { departmentInchargeDropdownFilterBy } = useContext(TypemasterDropdownContext);
  const { getGenericDropdownData, isGenricParamsDropdownDataLoading, usersDropdownData } = useContext(GenericDropdownContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm();
  let navigate = useNavigate();

  const [submitMessage, setSubmitMessage] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const departmentInchargeDropdownFilterData = usersDropdownData?.filter((info) => departmentInchargeDropdownFilterBy.some((rl) => info.role?.find((role) => rl.value === role.role_value)));

  const departmentName = watch("department_name");
  const departmentDisplayName = watch("department_display_name");

  const handleBlur = (e) => {
    console.log(e.target.value);
    if (departmentName === "") {
      reset({
        department_name: e.target.value,
      });
    }
    if (departmentDisplayName === "") {
      reset({
        department_display_name: e.target.value,
      });
    }
  };

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    const access = secureLocalStorage.getItem("access");
    var form_data = JSON.stringify({
      department_name: data.department_name,
      department_display_name: data?.department_display_name,
      department_incharge_id: data?.department_incharge?.value,
      created_by_id: userID,
      updated_by_id: userID,
    });

    const url = VARIABLES.url + "/api/department/";
    console.log(url);

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      data: form_data,
    };

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Department created successfully",
        });
        getGenericDropdownData();
        setTimeout(() => {
          navigate("/department");
        }, 2000);
        reset();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          setSubmitLoading(true);
          RefreshToken();
        } else {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to create department",
          });
        }
      });
  };

  useEffect(() => {
    getDepartmentData();
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title"> Create Department </h4>
          </div>
          <div class="nk-block-head-content">
            <a
              id="button-back"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="button-back"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div class="card">
          <div class="card-inner">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row g-4">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Department Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter department name"
                    id="text-forms-department_name"
                    {...register("department_name", {
                      required: true,
                      onBlur: handleBlur,
                    })}
                  />
                  {errors.department_name && (
                    <span className="fs-8 text-danger">
                      Department name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="form-label">
                    Department Display Name
                    <span className="fs-8 text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter department display name"
                    id="text-forms-department_display_name"
                    {...register("department_display_name", {
                      required: true,
                      onBlur: handleBlur,
                    })}
                  />
                  {errors.department_display_name && (
                    <span className="fs-8 text-danger">
                      Department display name is required
                    </span>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Department Incharge</label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={departmentInchargeDropdownFilterData?.map((info) => ({
                            value: info.user.id,
                            label: `${info.user.first_name} ${info.user.last_name}`,
                          }))}
                          isClearable={true}
                          isLoading={isGenricParamsDropdownDataLoading}
                          id="select-forms-department_incharge"
                        />
                      )}
                      defaultValue=""
                      name="department_incharge"
                      control={control}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-light me-3"
                      id="submit-button-backbutton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {submitLoading ? (
                      <button
                        id="button-loading"
                        className="btn text-dark"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        id="submit-button-createdepartment"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateDepartment;
