import React, { useRef, useContext, useEffect, useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Document, Page } from "react-pdf";
import secureLocalStorage from "react-secure-storage";
import { NavLink, useNavigate } from "react-router-dom";
import RejectCandidate from "./RejectCandidate";
import { RefreshTokenContext } from "../../Context/RefreshTokenContext";

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      type="button"
      className="btn btn-info"
      id="rightarrow"
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        position: "absolute",
        top: "50%",
      }}
      onClick={onClick}
    >
      NEXT
      <em class="icon ni ni-caret-right"></em>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <button
      type="button"
      className="btn btn-info"
      id="leftarrow"
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        position: "absolute",
        top: "50%",
        zIndex: 10000,
      }}
      onClick={onClick}
    >
      <em class="icon ni ni-caret-left"></em>
      PREV
    </button>
  );
}

const Slide = ({ document }) => {
  const scale = 2;
  const [numPages, setNumPages] = useState(null);
  const [containerHeight, setContainerHeight] = useState("auto");
  const sliderRef = useRef(null);
  console.log("sliderRef: ", sliderRef)
  const [pdfHeight, setPdfHeight] = useState("auto");
  console.log("pdfHeight: ", pdfHeight)
  const pageRef = useRef(null);

  useEffect(() => {
    if (document) {
      setNumPages(null);
    }
  }, [document]);

  const handleLoadSuccess = ({ numPages }) => {
    console.log("PDF loaded successfully:", numPages, "pages");
    setNumPages(numPages);
  };

  const handleError = (error) => {
    console.error("PDF Error:", error);
  };

  const capturePageHeight = () => {
    if (pageRef.current) {
      const height = pageRef.current.clientHeight;
      console.log("PDF Page Height:", height);
      setPdfHeight(height + "px"); // Set the height of the container based on PDF page height
    }
  };

  useEffect(() => {
    capturePageHeight();
    window.addEventListener("resize", capturePageHeight);
    return () => window.removeEventListener("resize", capturePageHeight);
  }, [numPages]);

  // const renderPages = () => {
  //   const pages = [];
  //   for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
  //     pages.push(
  //       <div className="pdf-page">
  //         <Page key={pageNumber} pageNumber={pageNumber} 
  //         scale={scale}
  //          />
  //       </div>
  //     );
  //   }
  //   return pages;
  // };

  const renderPages = () => {
    const pages = [];
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
      pages.push(
        <div className="pdf-page" ref={pageRef} key={pageNumber}>
          <Page pageNumber={pageNumber} onLoadSuccess={capturePageHeight} />
        </div>
      );
    }
    return pages;
  };

  console.log("Document path:", document);
  console.log("Number of pages:", numPages);

  // useEffect(() => {
  //   const handleResize = () => {
  //     const slide = sliderRef.current.querySelector(".slick-slide");
  //     if (slide) {
  //       setContainerHeight(`${slide.offsetHeight}px`);
  //     }
  //   };

  //   handleResize();

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [numPages, document]);

  return (
    <div
      ref={sliderRef}
      className="landscape-pdf-container"
      // style={{ height: containerHeight }}
      style={{ height: pdfHeight }}
    >
      {document && (
        <Document
          file={document}
          onLoadSuccess={handleLoadSuccess}
          onError={handleError}
        >
          {numPages && renderPages()}
          {/* {[...Array(numPages).keys()].map((pageIndex) => (
            <Page key={pageIndex} pageNumber={pageIndex + 1} />
          ))} */}
        </Document>
      )}
    </div>
  );
};

const CandidatePoolResumes = (props) => {
  const { RefreshToken } = useContext(RefreshTokenContext);
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  console.log("currentIndex:", currentIndex);
  console.log("currentData:", currentData);
  console.log("ResumeData:", props.rawResumeData);
  const privileges = secureLocalStorage.getItem("privileges");

  const [storedStatus, setStoredStatus] = useState([]);
  console.log("storedStatus:", storedStatus);

  console.log(props);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const handleSliderChange = (index) => {
    console.log(index);
    console.log(props.rawResumeData[index]);
    setCurrentData(props.rawResumeData[index]);
    setCurrentIndex(index);
  };

  console.log(props.reqID);

  const moveToNextResume = (nextIndex) => {
    sliderRef.current.slickGoTo(nextIndex);
  };

  const handleSubmit = (is_shortlisting, data) => {
    const access = secureLocalStorage.getItem("access");
    console.log(data);
    let user = new FormData();
    user.append("requisition_id", props.reqID);
    user.append("candidate_status", is_shortlisting);
    user.append("updated_by_id", userID);
    var config = {
      method: "patch",

      url:
        props.shortlisting_type === "RC"
          ? `${VARIABLES.url}/api/candidateresume/${data?.id}/?shortlisting_type=RC`
          : `${VARIABLES.url}/api/candidateresume/${data?.id}/?shortlisting_type=SME`,
      headers: {
        Authorization: `Bearer ${access}`,
      },
      data: user,
    };

    for (var pair of user.entries()) {
      console.log(pair);
    }

    setSubmitLoading(true);
    axios(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setStoredStatus((prevStoredData) => ({
          ...prevStoredData,
          [data.id]: is_shortlisting,
        }));
        props.setSubmitMessage({
          type: "success",
          icon: "check",
          message: is_shortlisting === "drafted" ? "Candidate saved as draft" : "Candidate shortlisted successfully",
        });
        const nextIndex = (currentIndex + 1) % props.rawResumeData?.length;
        if (nextIndex === 0) {
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          setCurrentIndex(nextIndex);
          sliderRef.current.slickGoTo(nextIndex);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 403) {
          RefreshToken();
          setSubmitLoading(true);
        } else {
          setSubmitLoading(false);
          props.setSubmitMessage({
            type: "danger",
            icon: "cross",
            message: "Failed to shortlist candidate",
          });
        }
      });
  };

  useEffect(() => {
    if (props.rawResumeData?.length > 0) {
      handleSliderChange(props.resume_index);
      const statusMap = { 0: "drafted", 1: "accepted", 2: "rejected" };
      props.rawResumeData?.forEach((info) => {
        if (props.shortlisting_type === "RC") {
          setStoredStatus((prevStoredData) => ({
            ...prevStoredData,
            [info?.id]: statusMap[info?.candidate_status?.rc] || "unknown",
          }));
        } else {
          setStoredStatus((prevStoredData) => ({
            ...prevStoredData,
            [info?.id]: statusMap[info?.candidate_status?.sme] || "unknown",
          }));
        }
      });
    }
  }, [props.rawResumeData]);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      props.setSubmitMessage(null);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [props.submitMessage]);

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: handleSliderChange,
    initialSlide: props.resume_index,
  };

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between d-flex justify-end">
          <div class="nk-block-head-content">
            <a
              id="back-button"
              className="btn btn-light d-none d-sm-inline-flex"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
              <span>Back</span>
            </a>
            <a
              id="back-button"
              className="btn btn-icon btn-light d-inline-flex d-sm-none"
              onClick={() => navigate(-1)}
            >
              <em class="icon ni ni-arrow-left"></em>
            </a>
          </div>
        </div>
      </div>
      {props.isLoading ? (
        <div> loading... </div>
      ) : props.rawResumeData?.length === 0 ? (
        <p>No candidate data available.</p>
      ) : (
        <div className="resume-container">
          <div className="d-flex justify-center my-4 fs-5 fw-bold">
            {currentIndex + 1} of {props?.rawResumeData?.length}
            {/* <br />
            {currentData?.id} */}
          </div>
          {props?.from === "drafted" ||
            props?.from === "rc_applied" ||
            props?.from !== "selected" ? (
            [
              "pre_screening_candidate_resume_update",
              "shortlisting_candidate_resume_update",
            ].some((privilege) => privileges.includes(privilege)) ? (
              <>
                {(props?.from === 'showall'
                  ?
                  (currentData?.candidate_status?.rc !== 1 && currentData?.candidate_status?.rc !== 2 &&
                    currentData?.candidate_status?.sme !== 1 && currentData?.candidate_status?.sme !== 2)
                  : true) &&
                  <div className="resume-result">
                    <div>
                      {submitLoading ? (
                        <button className="btn text-dark" disabled>
                          Loading...
                        </button>
                      ) : storedStatus[currentData?.id] === "rejected" ? (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          disabled
                        >
                          REJECTED
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-danger"
                          id="submit-button-rejectresume"
                          data-bs-toggle="modal"
                          data-bs-target="#rejectcandidate"
                          state={{ editpost: currentData }}
                        >
                          REJECT
                        </button>
                      )}
                    </div>
                    {props?.from !== "drafted" &&
                      <div>
                        {submitLoading ? (
                          <button className="btn text-dark" disabled>
                            Loading...
                          </button>
                        ) : storedStatus[currentData?.id] === "drafted" ? (
                          <button
                            type="button"
                            className="btn btn-secondary"
                            disabled
                          >
                            Saved for later
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-warning"
                            id="submit-button-draftresume"
                            onClick={() => handleSubmit("drafted", currentData)}
                          >
                            Save for later
                          </button>
                        )}
                      </div>
                    }
                    <div>
                      {submitLoading ? (
                        <button className="btn text-dark" disabled>
                          Loading...
                        </button>
                      ) : storedStatus[currentData?.id] === "accepted" ? (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          disabled
                        >
                          ACCEPTED
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success"
                          id="submit-button-acceptresume"
                          onClick={() => handleSubmit("accepted", currentData)}
                        >
                          ACCEPT
                        </button>
                      )}
                    </div>
                  </div>
                }
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <Slider ref={sliderRef} {...settings}>
            {props.rawResumeData.map((data, dataIndex) => (
              <div key={dataIndex}>
                <div className="d-flex align-items-center justify-content-center">
                  <Slide key={dataIndex} document={data?.resume} />
                </div>
              </div>
            ))}
          </Slider>
          <div className="d-flex justify-center my-4 fs-5 fw-bold">
            {currentIndex + 1} of {props?.rawResumeData?.length}
          </div>
        </div>
      )}

      <div>
        {props.submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert ">
                <div
                  className={`alert alert-fill alert-${props.submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${props.submitMessage.icon}-circle`}
                  ></em>
                  <strong>{props.submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <RejectCandidate
        setSubmitMessage={props.setSubmitMessage}
        shortlisting_type={props.shortlisting_type}
        editdata={currentData}
        reqID={props.reqID}
        setStoredStatus={setStoredStatus}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        moveToNextResume={moveToNextResume}
        rawResumeData={props?.rawResumeData}
      />
    </>
  );
};

export default CandidatePoolResumes;
