import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constants";
import secureLocalStorage from "react-secure-storage";
import AddTechnicalQuestion from "./AddTechnicalQuestion";
import Snackbar from "../../../Base/Snackbar";
import { SnackbarContext } from "../../../Context/SnackbarContext";
import { InterviewQuestionContext } from "../../../Context/InterviewQuestionMasterContext";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import { SequenceContext } from "../../WorkflowStepperForm/SequenceContextProvider";
import AddStepperTab from "../AddStepperTab/AddStepperTab";
import { useLocation } from "react-router-dom";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";
// import { technicalInterviewQuestion } from "../../WorkflowStepperForm/StepperFormJson";

const TechnicalInterviewRound = ({ formId }) => {
  console.log(formId)

  //To store Technical Interview Details
  const [technicalDetails, setTechnicalDetails] = useState([]);
  const [isTechnicalDetailsLoading, setIsTechnicalDetailsLoading] = useState(false);

  //To get Technical Interview Details
  const getTechnicalInterviewDetails = async (resume_id, req_id, formId) => {
    setIsTechnicalDetailsLoading(true);
    const url =
      VARIABLES.url +
      `/api/technicalinterview/?candidate_job_id=${resume_id}&requisition_id=${req_id}&tab_id=${formId}`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setIsTechnicalDetailsLoading(false);
      setTechnicalDetails(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      setIsTechnicalDetailsLoading(false);
      console.log(error);
    }
  };

  const {
    technicalInterviewQuestion,
    getTechnicalInterviewQuestions,
    isQuestionLoading,
  } = useContext(InterviewQuestionContext);
  const TechnicalData = technicalDetails;

  console.log("technicalInterviewQuestion: ", technicalInterviewQuestion); //all interview questionnaire data
  console.log("prepopulated Interview details: ", TechnicalData); //all interview questionnaire data

  const { interviewActionDropdownData } = useContext(TypemasterDropdownContext);

  const { workflowSession, getWorkflowSession } = useContext(WorkflowContext);
  const {
    level,
    sequenceList,
    nextLevel,
    prevLevel,
    submitLoading,
    setSubmitLoading,
  } = useContext(SequenceContext);

  const { setSubmitMessage } = useContext(SnackbarContext); //message after submitting data

  const [newQueSubmitLoading, setNewQueSubmitLoading] = useState(false);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);

  const privileges = secureLocalStorage.getItem("privileges");

  const location = useLocation();

  const candidate_id = location.state?.candidate_id;
  const requisition_id = location.state?.requisition_id;
  const job_opening_id = location.state?.job_opening_id;

  const interviewerData = TechnicalData?.interview_details?.interview_feedback?.find((info) => info.interviewer.id === userID);
  console.log("interviewerData ", interviewerData);

  // const [isEditable, setIsEditable] = useState(interviewerData && interviewerData?.questions_data?.length === 0);
  // const [isEditable, setIsEditable] = useState(!(interviewerData?.questions_data?.length > 0));
  const [isEditable, setIsEditable] = useState(interviewerData?.candidate_accept_reject === 0 || interviewerData?.candidate_accept_reject === null);


  // const isDefaultDisabled = interviewerData && interviewerData?.questions_data?.length !== 0;

  const isFieldsDisabled =
    workflowSession.find((api) => api.tab_id === formId?.id) ||
    !privileges.includes("Technical Interview Create");

  const isDisabled =
    !isEditable ||
    isFieldsDisabled;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
  } = useForm();

  console.log(isDirty)

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleFormSubmit = (data) => {
    // if (isDirty) {
    handleSubmit(onSubmitScores)(data);
    // } else {
    //   nextLevel();
    // }
  };

  const isFormFilled = workflowSession.find((api) => api.tab_id == formId?.id);

  // Extract all categories from the Technical questions and store them into a single array
  const allCategories = technicalInterviewQuestion?.flatMap(
    (question) => question.category
  );
  console.log("allCategories: ", allCategories);

  //creating new set to remove duplicate values
  const uniqueCategories = [...new Set(allCategories)];
  console.log("uniqueCategories: ", uniqueCategories);

  const [manualMarks, setManualMarks] = useState(
    Array(uniqueCategories.length).fill("") //Create an array with a length equal to the number of unique categories and store with empty string
  );
  console.log(manualMarks);

  const [totalManualMarks, setTotalManualMarks] = useState(0); //stores the total of manual marks
  console.log(totalManualMarks);
  // Function to update the toggle status for a question
  const [toggleStatus, setToggleStatus] = useState({});

  const updateToggleStatus = (index, status) => {
    setToggleStatus((prevState) => ({
      ...prevState, //spreads previous state
      [index]: status, //update status with specified index
    }));
  };

  // State variable to hold row data
  const [rowData, setRowData] = useState([]);
  console.log("rowData:", rowData);

  // Update the row data array with the new score
  // const handleScoreChange = (index, score, comments, info) => {
  //   console.log(index);
  //   console.log(score);
  //   console.log(comments);
  //   console.log(info);
  //   if (score === null) {
  //     // Remove the row if the score is 0
  //     const updatedRowData = rowData.filter((_, rowIndex) => rowIndex !== index);
  //     setRowData(updatedRowData);
  //   } else {
  //     const updatedRowData = [...rowData]; //create a copy of rowData
  //     // if(info.question == rowData.map(api=>api.question))
  //     updatedRowData[index] = {
  //       ...updatedRowData[index], //existing data
  //       score: score, //update score
  //       comments: comments, // update comments
  //       info: info, //update info
  //     };
  //     setRowData(updatedRowData); //update state with all above data
  //   }
  // };

  const handleScoreChange = (index, score, comments, info) => {
    console.log("index: ", index);
    console.log("score: ", score);
    console.log("comments: ", comments);
    console.log("info: ", info);
    const existingEntryIndex = rowData.findIndex((row) => row.info.id === info.id);

    if (score === null) {
      // Remove the row if the score is null
      const updatedRowData = rowData.filter((_, rowIndex) => rowIndex !== existingEntryIndex);
      setRowData(updatedRowData);
    } else {
      const updatedRowData = [...rowData];

      if (existingEntryIndex === -1) {
        // If the entry doesn't exist, add it
        updatedRowData.push({
          score,
          comments,
          info,
        });
      } else {
        // If the entry exists, update it
        updatedRowData[existingEntryIndex] = {
          ...updatedRowData[existingEntryIndex],
          score,
          comments,
        };
      }

      setRowData(updatedRowData);
    }
  };


  // State variable to store categoryStats
  const [categoryStats, setCategoryStats] = useState({});
  console.log("categoryStats : ", categoryStats);

  // To calculate average marks
  const calculateAverageMarks = (category) => {
    if (categoryStats[category]) {
      // checks if that category presents in categoryStats
      const { count, totalScore } = categoryStats[category]; //takes count and totalscore from categorystats
      console.log("count", count);
      console.log("totalScore", totalScore);
      const averageMark = totalScore / count
      return Number.isInteger(averageMark) ? averageMark : averageMark.toFixed(2);
    }
    return 0; // if that category doesn't present in categoryStats returns 0
  };

  const handleManualMarksChange = (index, value) => {
    console.log("index", index + "value", value);
    const updatedManualMarks = [...manualMarks]; //create a copy of manual marks
    updatedManualMarks[index] = parseFloat(value); // Convert to number and default to 0
    setManualMarks(updatedManualMarks); //update the array of manual marks
    const total = updatedManualMarks.reduce((acc, mark) => acc + mark, 0); //Calculating total manual marks by adding all marks in the updated array
    const categoryCount = Object.keys(categoryStats).length; // Get the number of objects in categoryStats
    const averageMarks = total / categoryCount || 0; // Calculate average
    // setTotalManualMarks(averageMarks); //updated the state with total marks
    setTotalManualMarks(parseFloat(averageMarks.toFixed(2))); // Set the total manual marks with 2 decimal places
    // setTotalManualMarks(total); // Set the total manual marks with 2 decimal places
  };

  //form submission
  const onSubmitScores = (data) => {
    console.log(data);
    console.log("totalManualMarks: ", totalManualMarks);
    console.log("rowData: ", rowData);
    // const question_data = rowData.filter((api) => api !== undefined || api.question);
    const question_data = rowData.filter(
      (obj) =>
        typeof obj !== "undefined" &&
        obj.hasOwnProperty("info") &&
        obj.info.hasOwnProperty("question")
    );
    console.log(question_data);
    var user = JSON.stringify({
      candidate_interview_id: TechnicalData?.interview_details?.id,
      candidate_job_id: candidate_id,
      requisition_id: requisition_id,
      questions: question_data?.map((info) => ({
        question: info?.info?.question,
        score: info?.score,
        comments: info?.comments,
      })),
      categories: uniqueCategories.map((category, index) => ({
        category: category,
        score: manualMarks[index] || 0,
      })),
      interviewer_id: userID,
      comments: data.comment,
      candidate_accept_reject:
        data?.is_selected === "Draft"
          ? 0
          : data?.is_selected === "Accept"
            ? 1
            : data?.is_selected === "Reject"
              ? 2
              : null,
      total_score: totalManualMarks,
      created_by_id: userID,
      // updated_by_id: userID,
      workflow_session: {
        tab_id: formId?.id,
        workflow_id: formId?.WF,
        user_id: userID,
      },
    });

    console.log(user);

    // const isDraft = interviewerData?.candidate_accept_reject === 0

    var config = {
      // method: isDraft ? "patch" : "post",
      // url: isDraft
      //   ? `${VARIABLES.url}/api/technicalinterview/${TechnicalData?.interview_details?.id}/`
      //   : `${VARIABLES.url}/api/technicalinterview/`,
      method: "post",
      url: `${VARIABLES.url}/api/technicalinterview/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data) {
          setSubmitLoading(false);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message: `Interview completed and ${data?.is_selected === "Draft"
              ? "candidate drafted successfully."
              : data?.is_selected === "Reject"
                ? "candidate rejected successfully."
                : "candidate accepted successfully."
              }`,
          });
          getWorkflowSession(candidate_id, requisition_id);
          nextLevel();
          reset();
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: error?.response?.data?.message || "Interview failed",
        });
      });
  };

  // TAT Submit
  const onTechnicalTATSubmit = (data) => {
    console.log(data)
    var user = JSON.stringify({
      requisition_id: requisition_id,
      candidate_id: candidate_id,
      stage: "Technical Interview",
      created_by_id: userID
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api/candidatestatus/",
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(user);

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTechnicalInterviewQuestions(job_opening_id);
  }, []);

  useEffect(() => {
    getTechnicalInterviewDetails(candidate_id, requisition_id, formId?.id);
    if (privileges.includes("Technical Interview Create")) {
      onTechnicalTATSubmit();
    }
  }, []);

  useEffect(() => {
    //object to store the count of questions and total scores for each category
    const newCategoryStats = {};
    console.log(newCategoryStats);

    // Iterate over each row in the `rowData`
    rowData?.forEach((row) => {
      // Extract the category and score from the row
      const category = row?.info?.category;
      const score = parseInt(row?.score);

      // If the category already exists in `newCategoryStats`
      // update count and total score
      if (newCategoryStats[category]) {
        newCategoryStats[category].count++;
        newCategoryStats[category].totalScore += score;
      } else {
        newCategoryStats[category] = { count: 1, totalScore: score }; //if not then , initialize it with count 1 and the current score
      }
    });
    setCategoryStats(newCategoryStats); //update the state with new stats
  }, [rowData]);

  // useEffect(() => {
  //   if (manualMarks.length !== 0) {
  //     const total = manualMarks.reduce((acc, mark) => acc + mark, 0);
  //     setTotalManualMarks(total);
  //   }
  // }, [manualMarks]);

  useEffect(() => {
    if (manualMarks.length !== 0) {
      const total = manualMarks.reduce((acc, mark) => acc + parseFloat(mark) || 0, 0); // Parse to float
      console.log(total)
      console.log(manualMarks)
      const categoryCount = Object.keys(categoryStats).length; // Get the number of objects in categoryStats
      console.log(categoryCount)
      const average = categoryCount > 0 ? total / categoryCount : 0; // Calculate average
      setTotalManualMarks(parseFloat(average.toFixed(2))); // Set the total manual marks with 2 decimal places
      // setTotalManualMarks(total); // Set the total manual marks with 2 decimal places
    } else {
      setTotalManualMarks(0); // Reset to 0 if no manual marks
    }
  }, [manualMarks]);

  useEffect(() => {
    var defaultValues = {};
    if (interviewerData?.categories_data) {
      const category_data = interviewerData?.categories_data?.map(
        (api) => api.score
      );
      setManualMarks(category_data);
    }

    if (interviewerData?.questions_data) {
      // const question_data = interviewerData?.questions_data?.map((api) => {
      //   return { score: api.score, comments: api.comments };
      // });

      const question_data = interviewerData?.questions_data.map((api) => {
        // Find the matching question in technicalinterviewQuestion
        const matchingQuestion = technicalInterviewQuestion.find(
          (techQuestion) => techQuestion.question === api.question
        );
        console.log("matching: ", matchingQuestion)

        return {
          score: api.score,
          comments: api.comments,
          info: matchingQuestion || null, // Add the matching question as 'info'
        };
      });
      setRowData(question_data);


      const initialToggleStatus = question_data.reduce((acc, question, index) => {
        acc[index] = question.score > 0;
        return acc;
      }, {});
      setToggleStatus(initialToggleStatus);
    }

    if (interviewerData) {
      defaultValues.comment = interviewerData?.comments;
      defaultValues.is_selected =
        interviewerData?.candidate_accept_reject === 0
          ? "Draft"
          : interviewerData?.candidate_accept_reject === 1
            ? "Accept"
            : interviewerData?.candidate_accept_reject === 2
              ? "Reject"
              : "Draft";
      setTotalManualMarks(interviewerData?.total_score);
    }
    else {
      defaultValues.is_selected = "Draft";
    }
    reset(defaultValues);
  }, [interviewerData]);

  const handleAverageMarksChange = (index, value) => {
    console.log(index + "value: ", value)
    const updatedManualMarks = [...manualMarks];
    console.log(updatedManualMarks)
    updatedManualMarks[index] = parseFloat(value) || 0;
    setManualMarks(updatedManualMarks); // Update the state with new values
  };

  useEffect(() => {
    let needsUpdate = false;
    const updatedManualMarks = [...manualMarks];

    uniqueCategories.forEach((category, index) => {
      // if (updatedManualMarks[index] === '' || updatedManualMarks[index] === undefined) {
      updatedManualMarks[index] = calculateAverageMarks(category);
      console.log(calculateAverageMarks(category))
      setValue(`manualmarks.${index}`, calculateAverageMarks(category)); // Set the value using setValue from react-hook-form to handle the required issue
      needsUpdate = true;
      console.log("first")
      // }
    });

    if (needsUpdate) {
      setManualMarks(updatedManualMarks);
      console.log("first")
    }
  }, [categoryStats]);

  // useEffect(() => {
  //   uniqueCategories.forEach((category, index) => {
  //     if (manualMarks[index] === '' || manualMarks[index] === undefined) {
  //       setValue(`manualmarks.${index}`, calculateAverageMarks(category) || 0);
  //     }
  //   });
  // }, [uniqueCategories, manualMarks, setValue]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between">
          <div className="nk-block-head-content d-flex">
            <h4 className="nk-block-title">Technical Interview Round</h4>
            {isQuestionLoading || newQueSubmitLoading ? (
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              ""
            )}
          </div>
          {privileges.includes("rc_update_provision") && (
            <div className="nk-block-head-content pe-2">
              {interviewerData && interviewerData?.length !== 0 && (
                <>
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={toggleEdit}
                    >
                      {isEditable ? "Cancel" : "Edit"}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
          {/* checks that is_iterable is true or not 
          if yes then it shows the button/form to create an alternate tab */}
          {formId?.is_iterable && formId?.is_iterable == true && (
            <AddStepperTab formId={formId} />
          )}
        </div>
      </div>
      {isQuestionLoading || isTechnicalDetailsLoading ? (
        <div></div>
      ) : (
        <div className="col-lg-12 col-md-12 col-sm-12">
          <>
            {TechnicalData?.interview_details ? (
              <>
                {technicalInterviewQuestion &&
                  technicalInterviewQuestion.length !== 0 ? (
                  <div className="card">
                    <div className="card-inner">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Category name</th>
                              <th>Average of Questions</th>
                              <th> Manual Marks <span className="text-danger"> *</span> </th>
                            </tr>
                          </thead>
                          <tbody>
                            {uniqueCategories?.map((category, index) => (
                              <tr key={index}>
                                <td>{category}</td>
                                <td onChange={(e) => handleAverageMarksChange(index, e.target.value)}                                >
                                  {calculateAverageMarks(category) ? calculateAverageMarks(category) : 0}
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id={`number-forms-manualmarks[${index}]`}
                                    {...register(`manualmarks.${index}`, {
                                      required: !(
                                        (interviewerData &&
                                          interviewerData?.categories_data?.length !== 0 &&
                                          !interviewerData && interviewerData?.questions_data?.length === 0) ||
                                        !calculateAverageMarks(category)
                                      ),
                                      min: { value: 0, message: "Value must be at least 0" }, // Minimum value validation
                                      max: { value: 5, message: "Value cannot exceed 5" }, // Maximum value validation                                
                                    })}
                                    value={manualMarks[index] !== '' ? manualMarks[index] : ''}
                                    step="0.01"
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
                                        handleManualMarksChange(index, value);
                                      }
                                    }}
                                    disabled={
                                      (isFieldsDisabled) ||
                                      !calculateAverageMarks(category)
                                    }
                                  />
                                  {errors.manualmarks &&
                                    errors.manualmarks[index] && (
                                      <span className="fs-8 text-danger">
                                        {errors.manualmarks[index].message || "Manual marks are required"}
                                      </span>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row ps-5">
                      {/* <div className="d-flex justify-between"> */}
                      <div className="col-6">
                        {(interviewerData?.candidate_accept_reject === null || interviewerData === undefined) && (
                          <button
                            id="button-button-addtechquestion"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#addtechquestion"
                            aria-label="Close"
                          >
                            Add question
                          </button>
                        )}
                      </div>

                      <div className="col-6 d-flex justify-end">
                        <label className="w-25 p-2 fw-bold">
                          Total Marks :
                        </label>
                        <div className="form-control w-25 me-4">
                          {totalManualMarks}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>

                    <form
                      onSubmit={handleSubmit(handleFormSubmit)}
                      autoComplete="off"
                    >
                      <div className="card-inner">
                        <div className="table-responsive" style={{ msOverflowY: "scroll", scrollbarWidth: "thin", maxHeight: "600px" }}>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th className="col-question">Question</th>
                                <th className="col-answer">Expected Answer</th>
                                <th className="col-category">Category</th>
                                <th>Status</th>
                                <th>
                                  <span>Score</span>
                                  <br />
                                  <span>(Out of 5)</span>
                                </th>
                                <th className="col-comments">Comments</th>
                              </tr>
                            </thead>
                            <tbody>
                              {technicalInterviewQuestion?.map(
                                (info, index) => (
                                  <tr key={index}>
                                    <td className="col-question">{info.question}</td>
                                    <td className="col-answer">{info.answer}</td>
                                    <td className="col-category">{info.category}</td>
                                    <td>
                                      <div className="nk-tb-col">
                                        <div className="custom-control custom-switch">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`customSwitches${index}`}
                                            checked={toggleStatus[index] || false}
                                            onChange={(e) => {
                                              updateToggleStatus(index, e.target.checked);
                                              if (!e.target.checked) {
                                                // Set score to 0 when toggle is turned off
                                                handleScoreChange(index, null, rowData?.find((data) => data?.info?.id === info?.id)?.comments || "", info);
                                              }
                                              if (e.target.checked) {
                                                handleScoreChange(index, 0, rowData?.find((data) => data?.info?.id === info?.id)?.comments || "", info);
                                              }
                                            }}
                                            disabled={isFieldsDisabled}
                                          />
                                          <label
                                            className="custom-control-label"
                                            id="button-button-status"
                                            htmlFor={`customSwitches${index}`}
                                          ></label>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="col-score">
                                      <input
                                        type="number"
                                        id={`number-forms-score[${index}]`}
                                        className="form-control"
                                        {...register(`scores.${index}`, {
                                          min: { value: 0, message: "Score must be at least 0" }, // Minimum score validation
                                          max: { value: 5, message: "Score cannot exceed 5" }, // Maximum score validation
                                        })}
                                        // value={rowData[index]?.score || ""}
                                        value={rowData?.find((data) => data?.info?.id === info?.id)?.score || ""}
                                        onWheel={(e) => e.target.blur()}
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (value === "" || /^\d+(\.\d{0,2})?$/.test(value)) {
                                            handleScoreChange(
                                              index,
                                              value,
                                              rowData[index]?.comments || "", // Pass the previous comments or an empty string
                                              info // Pass the row info
                                            );
                                          }
                                        }}
                                        disabled={
                                          !toggleStatus[index] ||
                                          (isFieldsDisabled)
                                        }
                                      />
                                      {errors.scores && errors.scores[index] && (
                                        <span className="fs-8 text-danger">
                                          {errors.scores[index].message}
                                        </span>
                                      )}
                                    </td>
                                    <td className="col-comments">
                                      <textarea
                                        className="form-control"
                                        id={`textarea-forms-comments[${index}]`}
                                        {...register(`comments[${index}]`)}
                                        // value={rowData[index]?.comments || ""}
                                        value={rowData?.find((data) => data?.info?.id === info?.id)?.comments || ""}
                                        style={{
                                          width: "100%",
                                          minWidth: "300px",
                                          maxWidth: "600px",
                                        }}
                                        onChange={(e) =>
                                          handleScoreChange(
                                            index,
                                            rowData?.find((data) => data?.info?.id === info?.id)?.score || "", // Previous score for the row or empty string
                                            e.target.value,
                                            info // info of row
                                          )
                                        }
                                        disabled={
                                          !toggleStatus[index] ||
                                          (isFieldsDisabled)
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-lg-6 mt-4 mb-4">
                          <div className="form-group">
                            <label className="form-label">Comments</label>
                            <textarea
                              className="form-control"
                              id="text-forms-comment"
                              placeholder="Enter Comment"
                              {...register("comment")}
                              disabled={isFieldsDisabled || interviewerData && interviewerData?.candidate_accept_reject !== null && interviewerData?.candidate_accept_reject !== 0}
                            />
                          </div>
                        </div>
                        <div className="col-6"></div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                          <ul className="row g-3">
                            {interviewActionDropdownData.map(
                              (action, index) => (
                                <li className="col-sm-4" key={index}>
                                  <div className="custom-control custom-radio me-3">
                                    <input
                                      type="radio"
                                      id={`checkbox-form-is-selected-hrreview-${action.value.toLowerCase()}`}
                                      className="custom-control-input"
                                      value={action.value}
                                      {...register("is_selected", {
                                        required: true,
                                      })}
                                      disabled={isFieldsDisabled || interviewerData && interviewerData?.candidate_accept_reject !== null && interviewerData?.candidate_accept_reject !== 0}
                                    />
                                    <label
                                      className="custom-control-label"
                                      for={`checkbox-form-is-selected-hrreview-${action.value.toLowerCase()}`}
                                    >
                                      {action.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                          {errors.is_selected && (
                            <span className="fs-8 text-danger">
                              Please select any one
                            </span>
                          )}
                        </div>

                        <div className="col-12 d-flex mt-4">
                          <div className="form-group">
                            {level !== 0 && (
                              <button
                                type="button"
                                className="btn btn-lg btn-primary me-3"
                                id="button-button-prevlevel"
                                onClick={() => prevLevel()}
                              >
                                Prev
                              </button>
                            )}
                          </div>
                          <div className="form-group">
                            {submitLoading ? (
                              <button className="btn text-dark" disabled>
                                Loading...
                              </button>
                            ) : (
                              <>
                                {sequenceList.length - 2 > level ? (
                                  interviewerData !== undefined && interviewerData.candidate_accept_reject !== null && interviewerData.candidate_accept_reject !== 0 ? (
                                    <button
                                      type="button"
                                      className="btn btn-lg btn-primary"
                                      id="submit-button-techinterview"
                                      onClick={() => nextLevel()}
                                    >
                                      Next
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                      id="submit-button-techinterview"
                                    >
                                      Next
                                    </button>
                                  )
                                ) : interviewerData !== undefined && interviewerData?.candidate_accept_reject !== null && interviewerData?.candidate_accept_reject !== 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn-lg btn-primary"
                                    id="submit-button-techinterview"
                                    onClick={() => nextLevel()}
                                  >
                                    Submit
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    id="submit-button-techinterview"
                                  >
                                    Submit
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div>No Questions found for Technical interview round.</div>
                )}
              </>
            ) : (
              "Technical Interview is not scheduled for this candidate."
            )}
          </>
        </div>
      )}

      <Snackbar />
      <AddTechnicalQuestion setNewQueSubmitLoading={setNewQueSubmitLoading} />
    </>
  );
};

export default TechnicalInterviewRound;
